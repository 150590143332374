(function(window){
    var fmAjaxTool = (function () {
        return  {

            prepareAjaxRequest : function ( method, route, data ){
                performAjaxRequest({
                    'method'        : method,
                    'route'         : route,
                    'data'          : data,
                });
            },            

            /**
             * Do ajax request with pass data
             * @param   ajaxCallData                    object, configuration data
             * @param   ajaxCallData.method             string, submit method
             * @param   ajaxCallData.route              string, submit route
             * @param   ajaxCallData.data               serialize string || jquery object
             * @param   ajaxCallData.opt.serialize      boolean, validate if ajaxCallData.data it's serliaze string
             * @param   ajaxCallData.opt.alertWith      string, choose alert type options ( swal, fmNotification )
             * @return        
             */            
            performAjaxRequest : function ( ajaxCallData )
            {
                var self = this;
                data = ( ajaxCallData.opt.serialize ) ? ajaxCallData.data.serialize() : ajaxCallData.data;
                $.ajax({
                    headers: { 'X-CSRF-Token': $('#_token').val() },
                    method: ajaxCallData.method,
                    url:    ajaxCallData.route,
                    data:   data,
                })
                .done(function( msg, textStatus ) {
                    self.displayAlertWith( ajaxCallData.opt.alertWith, "success", msg );
                    return true;
                })
                .fail(function( msg, textStatus  ) {
                    self.displayAlertWith( ajaxCallData.opt.alertWith, "warning", msg );
                    return false;
                });
            },

            displayAlertWith : function ( alertType, status, msg )
            {
                var self = this;
                switch( alertType ) {
                    case 'fmNotification' :
                        return fmNotification.display({ 
                            text : self.formatAjaxRespondForDisplay( msg[status] ), 
                            type : status,
                            from : 'top', 
                        });
                    break;

                    case 'swal':    
                        return self.displaySwal({ 
                            title: status, 
                            text : msg, 
                            type : status, 
                        });
                    break;
                }  
            },

            displaySwal : function ( opt )
            {
                var self = this;
                swal({
                    title   : opt.title, 
                    text    : self.formatAjaxRespondForDisplay( opt.text, opt.type ),  
                    type    : opt.type,
                    html    : true,
                });                
            },

            formatAjaxRespondForDisplay : function ( jsonDataArray, status )
            {
                status = ( typeof(status) !== 'undefined' ) ? status : false;
                
                if( typeof(jsonDataArray) === "string" ){
                    return jsonDataArray;
                }

                if( typeof(jsonDataArray) === "object" ){

                    if( typeof(jsonDataArray[status]) !== 'undefined' ){
                        return jsonDataArray[status];
                    }

                    if( typeof(jsonDataArray.responseJSON[status]) !== 'undefined' ){
                        return jsonDataArray.responseJSON[status];
                    }

                    outPutData = "<ul class='list-unstyled'>";                        
                    // loop through the errors to be output.    
                    for (var prop in jsonDataArray) {
                        outPutData += "<li>" + jsonDataArray[prop] + "</li>";
                    }

                    outPutData += "</ul>";
                    return outPutData;
                }
            },

            getElementType : function  (object) {
               
                switch( typeof(object) ) {
                    
                    case null:
                        return null;
                        break;
                    
                    case undefined:
                        return null;
                        break;
                    
                    case 'string':
                        return object;

                    
                    default:
                        return object;
                }
            }
        }
    }());
    window.fmAjaxTool = fmAjaxTool;
}(window));