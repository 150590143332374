var FormWizard = function () {
    return {
        //main function to initiate the module
        init: function () {
            
            if ( ! jQuery().bootstrapWizard ) {
                return;
            }

            var tabLevelCompleted           = "completed"; //
            var form                        = $('#organization-registration'),
                error                       = $('.alert-danger', form),
                success                     = $('.alert-success', form),
                ajaxOutput                  = error.children('.ajax-msg'),
                regStep                     = farmersmarket.current_reg_step, //tab1, tab2 ..
                navigation                  = $('ul.steps', form),
                tabLast                     = $('#tab' + navigation.find('li').length ),
                inputsRules                 = form.find("input.form-control, textarea.form-control, select.form-control"),
                currentRegStepIndex         = ( regStep === tabLevelCompleted ) ? tabLevelCompleted :  parseInt( regStep.substring(3)) - 1,
                currentTabsInputsUpdated    = false,
				addressFieldsNames			= ['address_1', 'city', 'state', 'zip'];
            
            this.switchTogglerListener();      

            $.validator.addMethod("goverment_members[]", function(value, elem, param) {
                
                if($(".goverment_members:checkbox:checked").length > 0){
                   return true;
                }else {
                   return false;
                }
            },"You must select at least one!");

            /**
             * Validating form inputs
             */
            form.validate({
                
                doNotHideMessage: true, //this option enables to show the error/success messages on tab switch.
                errorElement: 'span', //default input error message container
                errorClass: 'help-block help-block-error', // default input error message class
                focusInvalid: false, // do not focus the last invalid input

                rules : this.getTabRules( inputsRules ),
                
                // custom messages 
                messages: {},

                // render error placement for each input type
                errorPlacement: function (error, element) 
                {
                    switch(element.attr("type")){
                        case 'radio':

                            var radioError = error[0];
                            var errorId = $(radioError).attr('id');

                            if( errorId.indexOf('[') !== -1 ){

                                $(radioError).attr('id', errorId.substr(0, errorId.length-8) + "-error" );
                            }                        
                            
                            element.closest('.radio-list').append(error);
                            break;

                        case 'checkbox':
                            var checkBoxError = error[0];
                            var errorId = $(checkBoxError).attr('id');

                            if( errorId.indexOf('[') !== -1 ){

                                $(checkBoxError).attr('id', errorId.substr(0, errorId.length-8) + "-error" );
                            }

                            element.closest('.checkbox-list').append(error);
                            break;

                        default:
                            error.insertAfter(element);
                            break;
                    }
                },


                //display error alert on form submit   
                invalidHandler: function (event, validator) 
                { 
                    success.hide();
                    error.show();
                    Metronic.scrollTo(error, -200);
                },

                
                highlight: function (element) 
                { // hightlight error inputs
                    $(element)
                        .closest('.form-group').removeClass('has-success').addClass('has-error'); // set error class to the control group
                },

                
                unhighlight: function (element) 
                { // revert the change done by hightlight
                    $(element)
                        .closest('.form-group').removeClass('has-error'); // set error class to the control group
                },

                success: function (label) {},

                submitHandler: function (form) 
                {
                    success.show();
                    error.hide();
                    //add here some ajax code to submit your form or just call form.submit() if you want to submit the form without ajax
                }
            });

            // display confirmation info in last tab
            var displayConfirm = function() 
            {
                // change to tab last #tab4
                // $('#tab4 .form-control-static', form).each(function(){
                tabLast.find('.form-control-static').each(function(){
                    
                    var input = $('[name="'+$(this).attr("data-display")+'"]', form);
                    
                    if (input.is(":radio")) {
                        input = $('[name="'+$(this).attr("data-display")+'"]:checked', form);
                    }
                
                    if (input.is(":text") || input.is("textarea") || input.attr('type') == "url" ) {
                
                        $(this).html(input.val());
                
                    } else if (input.is("select")) {
                        
                        $(this).html(input.find('option:selected').text());
                
                    } else if ( input.is(":radio") && input.is(":checked")) {
                
                        $(this).html(input.attr("data-title"));
                
                    } else if ($(this).attr("data-display") == 'goverment_members[]') {

                        var goverment_members = [];
                        
                        $('[name="goverment_members[]"]:checked', form).each(function(){ 
                            
                            // goverment_members.push($(this).attr('data-title'));
                            goverment_members.push($(this).val());
                        
                        });
                        
                        $(this).html(goverment_members.join("<br>"));
                    }else if ( $(this).attr("data-display") == 'fm_metrics_use[]' ){

                        var fm_metrics_use = [];
                        
                        $('[name="fm_metrics_use[]"]:checked', form).each(function(){ 
                            data = {
                                general_research : 'To conduct general farmers market research',
                                fm_snap_support : 'Farmers Market SNAP Support Grantee reporting',
                                monitor_membership_activities : 'To monitor membership activities',
                                monitor_fm_programming : 'To monitor farmers market programming',
                            }
                            
                            fm_metrics_use.push(data[$(this).val()]);
                        
                        });
                        
                        $(this).html(fm_metrics_use.join("<br>"));                        
                    }
                });
            }

            // Form wizard controls
            form.bootstrapWizard({

                // 'nextSelector': '.button-next',
                'previousSelector': '.button-previous',
               
                onTabClick: function (tab, navigation, index, clickedIndex) {

                    return clickedIndex<=currentRegStepIndex;
                },
                
                onPrevious: function (tab, navigation, index) {
                    success.hide();
                    error.hide();
                    handleTitle(tab, navigation, index);
                },            

                onTabShow: function (tab, navigation, index) {
                    var total = navigation.find('li').length;
                    var current = index + 1;
                    var $percent = (current / total) * 100;
                    $('#organization-registration').find('.progress-bar').css({
                        width: $percent + '%'
                    });
                }
            });

            // Form wizard header tabs navigation
            var handleTitle = function(tab, navigation, index) 
            {

                var total = navigation.find('li').length;
                
                var current = index + 1;
                // set wizard title
                
                $('.step-title', $('#organization-registration')).text('Step ' + (index + 1) + ' of ' + total);

                // set done steps
                jQuery('li', $('#organization-registration')).removeClass("done");
                
                var li_list = navigation.find('li');

                for (var i = 0; i < index; i++) {
                    jQuery(li_list[i]).addClass("done");
                }

                if (current == 1) {
                    
                    $('#organization-registration').find('.button-previous').hide();
                
                } else {
                
                    $('#organization-registration').find('.button-previous').show();
                
                }

                if (current !== total) {
                
                    $('#organization-registration').find('.button-next').show();
                    $('#organization-registration').find('.button-submit').hide();
                
                } else {

                    $('#organization-registration').find('.button-next').hide();
                    $('#organization-registration').find('.button-submit').show();
                    displayConfirm();
                }
                
                Metronic.scrollTo($('.page-title'));
            }

            // btns init state
            form.find('.button-previous').hide();
            form.find('.button-submit').hide();
            /**
             * Set wizzard current tab index base on users reg_setp that comes from db
             * if completed redirect to user profile
             * if tab1 means first tab to display
             */
            if ( currentRegStepIndex  !== tabLevelCompleted  ){
                
                $('#organization-registration').bootstrapWizard('show', currentRegStepIndex  );
                handleTitle( $('#tab' + $('#organization-registration').bootstrapWizard('currentIndex')), navigation, $('#organization-registration').bootstrapWizard('currentIndex') );
            }

            // bool, if any input in the current tab was updated
            currentTabsInputsUpdated = this.inputUpdated( form.bootstrapWizard('currentIndex') );


            // LAST TAB: submit email to admin
            $('#organization-registration .button-submit').click(function () 
            {
                error.hide();
                if( form.valid() !== false ){
                    var currentTabIndex     = form.bootstrapWizard('currentIndex'),
                        currentTab          = $('#tab' + ( currentTabIndex + 1)),
                        tabRoute            = currentTab.attr('data-route'),
                        tabMethod           = ( currentTab.attr('data-method') ) ? currentTab.attr('data-method') : 'PUT'; 
                        currentTabData      = currentTab.find('input.form-control, textarea.form-control, select.form-control').serialize();                
                    swal({   
                            title   :  "Submit",   
                            text    :  "You have completed the registration. Ready to submit?",   
                            type    :  "info",   
                            showCancelButton    : true,   
                            closeOnConfirm      : false,   
                            showLoaderOnConfirm : true,
                        
                        }, function(){   
                            // when need a custom post for specifiq way to collect data
                            if( currentTab.data('custom-post') ){

                                // group and post tracked metrics  by type ( per day, per month per year )
                                var trackMetricsTbls =  currentTab.find('.tbl-track-metrics');
                                
                                trackMetricsTbls.each(function(index){

                                    var metricType  = $(this).data('matrics');
                                    tblInputs       = $(this).find('input.form-control');

                                    data = "metric_type=" + metricType;
                                    tblInputs.each(function(index, item){
                                        data += "&" + $(this)[0].name + "=" + $(this)[0].value; 
                                        // data[$(this)[0].name] = $(this)[0].value;
                                    });
                                    
                                    prepareAjaxRequest( tabMethod, tabRoute, data, currentTabIndex );                
                                    // tblInputs       = $(this).find('input.form-control').serialize();
                                    // data            = tblInputs + "&metric_type=" + $(this).data('matrics');    
                                    // data =  {};
                                    // data.metric_type = metricType; 

                                });
                                
                                return true;
                            
                            }else{

                                // make a separate recuest if current tab data-route !== reg_step data-route ( where the reg_step saves  )
                                if( currentTab.data('post-regstep') ){
                                    prepareAjaxRequest( tabMethod, $('#tab1').data('reg-step-route') , "", currentTabIndex );
                                }

                                prepareAjaxRequest( tabMethod, tabRoute, currentTabData, currentTabIndex );                
                            
                            }
                        }
                    );
                }
            });

            //  NEXT BTN LISTENR
            $('#wizard-coninue-btn').on('click', function () {

                ajaxOutput.children('ul').remove();
                error.hide();

                if ( form.valid() !== false ) {
                    // get all inputs of current tab    
                    var currentTabIndex     = form.bootstrapWizard('currentIndex'),
                        currentTab          = $('#tab' + ( currentTabIndex + 1)),
                        tabRoute            = currentTab.attr('data-route'),
                        tabMethod           = ( currentTab.attr('data-method') ) ? currentTab.attr('data-method') : 'PUT'; 
                        currentTabData      = currentTab.find('input.form-control, textarea.form-control, select.form-control').serialize();

                    // make a separate recuest if current tab data-route !== reg_step data-route ( where the reg_step saves  )
                    if( currentTab.data('post-regstep') ){
                        prepareAjaxRequest( tabMethod, $('#tab1').data('reg-step-route') , "", currentTabIndex );
                    }

                    prepareAjaxRequest( tabMethod, tabRoute, currentTabData, currentTabIndex );
                }
            });

			$('#confirmed_address').focus(function(){
				let $this = $(this);
				let $alertWrapper = $('#confirmed_address_alert');

				return autoCompleteGeocoder(this)
					.then(function($address){
						$this
							.closest('.form-group')
							.removeClass('.has-error');
						
						$alertWrapper
							.attr('class', 'alert alert-success')
							.html("")
							.html("Address Validated successfully")
							.show();
					})
					.catch(function($error){
						$this
							.closest('.form-group')
							.addClass('has-error');
						
						$alertWrapper
							.attr('class', 'alert alert-danger')
							.html("")
							.html(`Could not validate ${$this.val()}, please try again.`)
							.show();
					});
			});
			/**
			 * @param $address
			 * @returns {Promise}
			 */
			let autoCompleteGeocoder = function($address){
				return new Promise(function($resolve, $reject){
					let geocoder = new google.maps.Geocoder();
					let autocomplete = new google.maps.places.Autocomplete($address, {componentRestrictions: {country: "us"}});
					autocomplete.setTypes(['address']);
					autocomplete.addListener('place_changed', function() {
				
						let place = autocomplete.getPlace();
						if (!place.geometry) {
							return $reject(place);
						}
						
						parseGoogleResponse(place.address_components);
						
						//let formatedAddress = formatAddress(place.address_components);
						return $resolve(place.formatted_address);
					});
				});
			
			};
			
			function formatAddress($addressComponents){
				let streetNumber =  getAddressComponentType('street_number', $addressComponents);
				let streetAddress =  getAddressComponentType('route', $addressComponents);
				let city =  getAddressComponentType('locality', $addressComponents);
				let state =  getAddressComponentType('administrative_area_level_1', $addressComponents);
				let zip =  getAddressComponentType('postal_code', $addressComponents);
				let country =  getAddressComponentType('country', $addressComponents);
				return `${streetNumber} ${streetAddress}, ${city}, ${state}, ${zip}, ${country}`;
			}
			
			function parseGoogleResponse(components) {
				$.each(components, function(index, component) {
					$.each(component.types, function(key, type) {
						if (type === 'street_number') {
							$("input[name=address_1]").val(component.long_name)
						}
						if (type === 'route') {
							addressInput = $("input[name=address_1]");
							addressInput.val(`${addressInput.val()}  ${component.long_name}`);
						}
						if (type === 'locality') {
							$("input[name=city]").val(component.long_name)
						}
						if(type === 'administrative_area_level_1'){
							$("input[name=state]").val(component.short_name)
						}
						if (type === 'postal_code') {
							$("input[name=zip]").val(component.long_name)
						}
						if (type === 'country') {
							//$("input[name=country]").val(component.long_name)
						}
					})
				})
			}
			
			function getAddressComponentType($type, $addressComponents){
				let componentType;
				$.each($addressComponents, function(index, component){
					if(component.types.indexOf($type) !== -1){
						console.log(component.long_name)
						return component.long_name;
					}
				})
			}
			
            // PREPARE AJAX REQUEST.             
            var prepareAjaxRequest = function ( method, route, data, currentTabIndex )
            {
                //remove any displayed errors from ui
                ajaxOutput.empty();

                // persisting data to perfonal profile || organization profile || partner profile 
                performAjaxRequest({
                    'method'            : method,
                    'route'             : route,
                    'data'              : data,
                    'currentTab'        :( (currentTabIndex + 1) === navigation.find('li').length  ) ? tabLevelCompleted : "tab" + ( currentTabIndex  + 2 ),// completed || tab1, tab2 ...
                });
            };
			
            // PERFORMA AJAX REQUEST
            var performAjaxRequest = function ( ajaxCallData )
            {
                var bntNext         = $('#wizard-coninue-btn');
                var bntSubmit       = $('#submit-btn');
                var btnPrevious     = $('.button-previous');


                // disable all buttons
                bntNext.button('loading');
                bntSubmit.button('loading');
                btnPrevious.addClass('disabled');

                //add current tab index to the serialized data
                data = ajaxCallData.data + "&reg_step=" + ajaxCallData.currentTab;

                $.ajax({
                    headers : { 'X-CSRF-Token': $('#_token').val() },
                    method  : ajaxCallData.method,
                    url     : ajaxCallData.route,
                    data    : data,
                })
                
                .done(function( msg, textStatus ) {

                    // CURRENT TAB  ===  last tab
                    if ( ajaxCallData.currentTab === tabLevelCompleted ){
                        
                        swal({
                            title   : "Good job!", 
                            text    : "You information has been submitted!", 
                            type    : "success"
                        }, function () {
                            //redirect to edit profile
                            window.location = $('#redirect_route').val();
                        });
                        return true;    

                    }else{
                        fmNotification.display( { 'text' : msg.success , 'type' : msg.alertType } );
                    
                        error.hide();
                        ajaxOutput.empty();
                        
                        // go to next step
                        $('#organization-registration').bootstrapWizard('next');
                        
                        handleTitle( $('#tab' + $('#organization-registration').bootstrapWizard('currentIndex')), $('ul.steps', form), $('#organization-registration').bootstrapWizard('currentIndex') );
                        // return true;                    
                    }

                    // unblock next and back btn
                    bntNext.button('reset');
                    bntSubmit.button('reset');
                    btnPrevious.removeClass('disabled');
                    return true;                    
                })

                .fail(function( msg, textStatus  ) {

                    // if not a reg_step ajax request
                    if ( ! ajaxCallData.currentTab !== "completed" ){


                        // show errors messages
                        var errorOutput = formatAjaxRespondForDisplay( msg.responseJSON );
                       
                        $(errorOutput).appendTo(ajaxOutput);

                        error.show();

                        return false;
                    }

                    bntNext.button('reset');
                    bntSubmit.button('reset');
                    btnPrevious.removeClass('disabled');                    
                });
            };

            // format out put message
            var formatAjaxRespondForDisplay = function ( jsonDataArray )
            {

                outPutData = "<ul class='list-unstyled'>";                        
                // loop through the errors to be output.    
                for (var prop in jsonDataArray) {
                    outPutData += "<li>" + jsonDataArray[prop] + "</li>";
                }

                outPutData += "</ul>";

                return outPutData;
            } 
        },

        getTabRules : function ( inputs ) {
            var self = this;
            
            formInputRules = {
                "goverment_members[]": { 
                    required: function(elem){
                        return $("input.goverment_members:checked").length == 0;
                    }
                },
                "network_type[]": { 
                    required: function(elem){
                        return $("input.network_type:checked").length == 0;
                    }
                },
                 "fm_metrics_use[]": { 
                    required: function(elem){
                        return $("inputfm_metrics_use:checked").length == 0;
                    }
                },
                 "operate_days[]": { 
                    required: function(elem){
                        return $("input.operate_days:checked").length == 0;
                    }
                },
                 "operate_days_frequency[]": { 
                    required: function(elem){
                        return $("input.operate_days_frequency:checked").length == 0;
                    }
                },
                 "integrity_producers_claims[]": { 
                    required: function(elem){
                        return $("input.integrity_producers_claims:checked").length == 0;
                    }
                },
                 "permanent_infrastructure[]": { 
                    required: function(elem){
                        return $("input.permanent_infrastructure:checked").length == 0;
                    }
                },
                "incentives_founded[]": { 
                    required: function(elem){
                        return $("input.incentives_founded:checked").length == 0;
                    }
                },
                "manged_options[]": { 
                    required: function(elem){
                        return $("input.manged_options:checked").length == 0;
                    }
                },

                // ORGANIZATION REGISTRATION
                "goverment_members[]" : {
                    required : function(elem){
                        return $("input.goverment_members:checked").length == 0;
                    }
                }
            };

            inputs.each( function(){
                var item = $(this);
                if( item.data('rules') ) {
                    formInputRules[item.attr('name')] =  self.setRules(item.data('rules'));
                }
            });

            return formInputRules;          
        },

        setRules : function ( rulesString ){
            attrs = rulesString.split("|");
            rulesObj = {};

            // get rules attrs 
            for (var i = attrs.length - 1; i >= 0; i--) {

                if( attrs[i].indexOf(':') == -1 ){
                    rulesObj[attrs[i]] = true;        
                }else{
                    rule = attrs[i].split(":");        
                    rulesObj[ rule[0] ] = rule[1];
                }

            };

            return rulesObj;
        },
        /**
         * get if any input in the currentTab was change 
         * return bool
         */
        inputUpdated : function ( currentTabIndex ){

            var currentTab       = $('#tab' + ( currentTabIndex + 1));
            var currentTabData   = currentTab.find('input.form-control, textarea.form-control, select.form-control');

            currentTabData.on('change', function(){
                return true;
            });
        },

        switchTogglerListener : function(){
            $('input.make-switch').on('switchChange.bootstrapSwitch', function(event, state) {
                if( state ){
                    return this.value = 1
                }

                return this.value = 0;
            });            
        },
    };
}();