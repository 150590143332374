(function (window){
	 // construct Data tables form js
	var fmDataTable = ( function(){

		return {
			dTable:{},
			/**
			 * @param  table         	jquery Obj, 
			 * @param  dataToDisplay 	string, optional, url from where data will be fetch as json ()
			 * @param  dataSource    	string, from where data will be fetch, DOM, AJAX, JAVASCRIPT.
			 * @param  tableId       	string, table id ( no # )
			 * @param  dataSrc       	string, name of the json object key in the controller that holds the data || callback .
			 * @return               	DataTable object
			 */
			init : function ( table, dataToDisplay, dataSource, tableId, dataSrc  ){
				var self 			= this;
				var displayFields 	= self.getCols(table);
				// default values 
				var dataSource 		= (typeof(dataSource) 		!== 'undefined') ? dataSource 		: 'data';
				var dataToDisplay 	= (typeof(dataToDisplay) 	!== 'undefined') ? dataToDisplay 	: false;
				var tableId 		= (typeof(tableId) 			!== 'undefined') ? tableId 			: false;
				var dataSrc 		= (typeof(dataSrc) 			!== 'undefined') ? dataSrc 			: 'users';
				// manage fields tables variables
				var inputsTypes 		= false;
				var inputsTypesColumns 	= false;
				var fieldsOwner			= false; 

				// set options
				self.options(tableId);
				// if checkboxses are need it.
				self.checkboxes(table);
				
				// none ajax dtables
				if (  ! dataToDisplay  ) {
					// dtable = table.DataTable();
					return self.dTable = table.DataTable();
				}

				// Create a dtables with ajax data source
				if ( dataSource === 'ajax' ) {
					self.dTable = table.DataTable({
			   			"processing": true,
					    "ajax" 	: {
					    	// dataSrc : dataSrc,
					    	url 	: dataToDisplay,
					    	dataSrc : function(json){
					    		// table manage fields
					    		if( json[dataSrc].fields && json[dataSrc].inputsTypes && json[dataSrc].inputsTypesColumns && json[dataSrc].fieldsOwner ){
									inputsTypes 		= json[dataSrc].inputsTypes;
									inputsTypesColumns 	= json[dataSrc].inputsTypesColumns;
					    			fieldsOwner 		= json[dataSrc].fieldsOwner;
					    			return json[dataSrc].fields;
					    		}
					    		
					    		return json[dataSrc];
					    	}
					    },
					    
					    "columns" : displayFields,

						"fnRowCallback" : function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
							// USERS
							if( tableId === "admin-users" ){
								// console.log(aData);
								var isAdmin = false;
								var isVendor = false;
						      	var rolesList = "";
								
								if( aData.rolesArray ){
									rolesSlugs = "";
									$.each( aData.rolesArray, function( index, elem ){
										rolesSlugs += elem.slug + " ";
										isAdmin = elem.slug === 'admin' ? true : false;
										isVendor = elem.slug === 'vendor' ? true : false;
									});
								}else{
									rolesSlugs = "";
									isArray = false;
								}
								//check box
						      	$('td:eq(0)', nRow).addClass('text-center');
								$('td:eq(0)', nRow).html('<input type="checkbox" data-name="' + aData.name + '" data-email="' + aData.email + '" class="checkboxes" value="'+ aData.id +'"/>');
						      	$('td:eq(2)', nRow).addClass('text-center');
								$('td:eq(3)', nRow).html(aData.organization_name);
								$('td:eq(4)', nRow).html('<a href="/profile/' + aData.userSlug + '/edit">' + aData.name + '</a>');
				
						      	//build the list of roles apply to each users
								rolesList += '<ul class="list-unstyled">';
						      	aData.rolesArray.forEach( function( element , index) {
									var role_name = element.name;
									rolesList += '<li>' + role_name.charAt(0).toUpperCase() + role_name.slice(1)  + '</li>';
						      	});
					      		rolesList += '</ul>';
						      	
					      		$('td:eq(6)', nRow).html(rolesList);
				
								if( aData.status ){
									var statusSelect = "";
									
									statusSelect += '<div class="input-group" style="width: 200px;">';
									statusSelect += '<select  name="user_status" class="form-control change_user_status">';
									
									for(var $status in fmcGlobalVars.statusMeta){
										if(fmcGlobalVars.statusMeta.hasOwnProperty($status)){
											statusSelect += `<option ${$status !== aData.status_slug ?"":"selected" } value="${$status}">
													${fmcGlobalVars.statusMeta[$status].name}
												</option>`;
										}
									}
									
									statusSelect += '</select>';
									statusSelect += '<span class="input-group-btn">';
									statusSelect += '<button type="submit" class="btn-change-status btn blue" disabled><i class="fa fa-check" style="color:white"></i></button>';
									statusSelect += '</span>';
									statusSelect += '<input type="hidden" class="route" value="/api/v1/users/'+aData.id+'/status">';
									statusSelect += '</div>';
									
									
									$('td:eq(7)', nRow).html(statusSelect);
									// $('td:eq(7)', nRow).html('<span class="label label-sm ' +  farmersmarket.lebelColor[aData.status_slug] + '">' + aData.status + '</span>');
						      	}
						      	
						      	//block user
						      	$('td:eq(8)', nRow).addClass('text-center');
						      	
						      	disableBlockInput = aData.status_slug === "block" ? "disabled" : false;
								blockInputBtnClass = !disableBlockInput ? 'btn-primary' : 'btn-default';

						      	isAdmin
						      		? $('td:eq(8)', nRow).html('<button class="tooltips btn btn-default disabled" data-original-title="Can not update admin" href="javascript:;"  ><i class="fa fa-exclamation-circle"></i></button>')
						      		: $('td:eq(8)', nRow).html('<button class="table-action-single btn '+ blockInputBtnClass +'" '+ disableBlockInput +' href="/api/v1/admin/users" data-action="block" data-id="'+ aData.id +'"><i class="fa fa-ban"></i></button>');
								
								//approve user
						      	$('td:eq(9)', nRow).addClass('text-center');

						      	disableActiveInput = aData.status_slug === "active" ? "disabled" : false;
								activeInputBtnClass = !disableActiveInput ? 'btn-primary' : 'btn-default';

								(aData.status_slug === "not_completed" || isAdmin )
									? $('td:eq(9)', nRow).html('<button class="tooltips btn btn-default disabled" data-original-title="User hasn\'t completed the registration" href="javascript:;"  ><i class="fa fa-exclamation-circle"></i></button>')
									: $('td:eq(9)', nRow).html('<button class="table-action-single btn '+ activeInputBtnClass +'" href="/api/v1/admin/users" data-action="approved"  data-id="'+ aData.id +'" '+ disableActiveInput +'><i class="fa fa-check"></i></button>');

								// minus icon for users with grantee role
								// plus icon for users without grantee icon
								granteeColIcon = ( rolesSlugs.indexOf("grantee") === -1 ) ? "fa-plus-square-o" : "fa-minus-square-o";
						      	addGranteeInputDisabled = (rolesSlugs.indexOf("grantee") === -1) ? false : "disabled";
								addGranteeInputBtnClass = !addGranteeInputDisabled ? 'btn-primary' : 'btn-default';

								//add grantee role
						      	$('td:eq(10)', nRow).addClass('text-center');
						      	
							      	( aData.status_slug === 'not_completed' || isAdmin || isVendor )
										? $('td:eq(10)', nRow).html('<button class="tooltips btn btn-default disabled" data-original-title="User hasn\'t completed the registration" href="#"><i class="fa fa-exclamation-circle"></i></button>')
										: $('td:eq(10)', nRow).html('<button class="table-action-single btn '+ addGranteeInputBtnClass +'"  href="javascript:;" data-roles="' + rolesSlugs + '" data-name="' + aData.name + '" data-email="' + aData.email + '" data data-action="update-roles" data-id="'+ aData.id +'"><i class="fa ' + granteeColIcon + '"></i></button>');

								$('td:eq(11)', nRow).addClass('text-center');
								(aData.status_slug ==='active' || isAdmin )
									? $('td:eq(11)', nRow).html('<button class="tooltips btn btn-default disabled" data-original-title="Delete user account." href="javascript:;"><i class="fa fa-exclamation-circle"></i></button>')
									: $('td:eq(11)', nRow).html('<button class="table-action-single btn btn-danger" data-action="delete-user" href="javascript" data-id="'+ aData.id +'" data-name="'+ aData.name +'" data-orgname="'+ aData.organization_name +'"><i class="fa fa-trash"></i></button>');
							}

							//impersonate column
							$('td:eq(12)', nRow).addClass('text-center');
							isAdmin
								? $('td:eq(12)', nRow).html('<button class="tooltips btn btn-default disabled" data-original-title="Delete user account." href="javascript:;"><i class="fa fa-exclamation-circle"></i></button>')
								: $('td:eq(12)', nRow ).html('<a class="btn btn-success" href="/impersonate/user-access/' +  aData.userSlug  + '"><i class="fa fa-user-secret"></i></a>');

							
							//Last login column
							$('td:eq(13)', nRow).addClass('text-center');
							// ADMINISTRATOR : GRANTEE USERS TABLE
							if( tableId === "tbl-admin-grantee" ){
								$('td:eq(2)', nRow ).html('<a href="/grantee/report-request/' +  aData.slug  + '">'+ aData.name +'</a>');
							}

							// ADMIN. Manage fields.
							if( tableId === "tbl-admin-manage-fields"){
								// $('td:eq(0)', nRow ).addClass('text-left');
								$('td:eq(5)', nRow ).addClass('text-center');
								$('td:eq(6)', nRow ).addClass('text-center');
								$('td:eq(7)', nRow ).addClass('text-center');
								$('td:eq(8)', nRow ).addClass('text-center');
								$('td:eq(9)', nRow ).addClass('text-center');
								$('td:eq(10)', nRow ).addClass('text-center');
								$('td:eq(11)', nRow ).addClass('text-center');

								$('td:eq(1)', nRow ).html('<input type="text" class="form-control" name="label" data-slug="'+ aData.slug +'" value="'+ aData.label +'">');
								$('td:eq(2)', nRow ).html('<select name="type_input_column" class="form-control" disabled>' + self.buildOptionsForSelectElement(inputsTypesColumns, aData.input_type_column ) + '</select>' );
								$('td:eq(3)', nRow ).html('<select name="input_type" class="form-control" disabled>' + self.buildOptionsForSelectElement(inputsTypes, aData.input_type_slug ) + '</select>' );
								$('td:eq(4)', nRow ).html('<input type="text" class="form-control" name="validations" value="'+ aData.validations +'">');
								$('td:eq(5)', nRow ).html('<div class="icheck-inline"><label><input name="required" type="checkbox" data-checkbox="icheckbox_square-blue" class="icheck checkboxInput" value="'+ aData.required +'" '+ self.boolCheck( aData.required ) +' ></label></div>');
								$('td:eq(6)', nRow ).html('<div class="icheck-inline"><label><input name="can_be_track" type="checkbox" data-checkbox="icheckbox_square-blue" class="icheck checkboxInput" value="'+ aData.can_be_track +'" '+ self.boolCheck( aData.can_be_track ) +' ></label></div>');

								if( aData.input_type_slug === "number" ){
									$('td:eq(7)', nRow ).html('<div class="icheck-inline"><label><input name="format_integer" type="checkbox" data-checkbox="icheckbox_square-blue" class="icheck checkboxInput" value="'+ aData.format_integer +'" '+ self.boolCheck( aData.format_integer ) +' disabled></label></div>');
									$('td:eq(8)', nRow ).html('<div class="icheck-inline"><label><input name="format_float" type="checkbox" data-checkbox="icheckbox_square-blue" class="icheck checkboxInput" value="'+ aData.format_float +'" '+ self.boolCheck( aData.format_float ) +' disabled></label></div>');
									$('td:eq(9)', nRow ).html('<div class="icheck-inline"><label><input name="format_currency" type="checkbox" data-checkbox="icheckbox_square-blue" class="icheck checkboxInput" value="'+ aData.format_currency +'" '+ self.boolCheck( aData.format_currency ) +' ></label></div>');
								}else{
									$('td:eq(7)', nRow ).html(' - ');
									$('td:eq(8)', nRow ).html(' - ');
									$('td:eq(9)', nRow ).html(' - ');
								}
								
								// http://farmersmarket.dev/admin/manage/fields/{belongsTo}/{fieldId}
								actionRoute = window.location.protocol + "//" + window.location.hostname + "/admin/manage/fields/" + fieldsOwner + "/" + aData.id;
								$('td:eq(10)', nRow ).html('<div class="icheck-inline"><label><input name="show_in_dashboard_table" type="checkbox" data-checkbox="icheckbox_square-blue" class="icheck checkboxInput" value="'+ aData.show_in_dashboard_table +'" '+ self.boolCheck( aData.show_in_dashboard_table ) +' >');
								$('td:eq(11)', nRow ).html('<div class="icheck-inline"><label><input name="show_in_merket_profile_table" type="checkbox" data-checkbox="icheckbox_square-blue" class="icheck checkboxInput" value="'+ aData.show_in_merket_profile_table +'" '+ self.boolCheck( aData.show_in_merket_profile_table ) +' >');
								$('td:eq(12)', nRow ).html('<button type="button" data-href="'+ actionRoute + '" class="metric-field-update" data-fiel-id="' + aData.id + '" data-owner="' + fieldsOwner  + '" ><i class="fa fa-save"></i></a>');
							}
					    }
					});

					// details for each row(users admin table validate it to be reusable)
					self.showDetails( table, self.dTable, tableId );

					if( tableId === "admin-users" ){
						self.userTableActions( table, self.dTable );
						self.changeStatusActions(table, self.dTable);
					}

					if( tableId === "tbl-admin-grantee" ){
						self.tablActionGrantee( table, self.dTable );
					}

					return self.dTable;
				}
				
				return self.dTable = table.DataTable({
					data 	: dataToDisplay,
					columns : displayFields,
				});
			},
			options : function (tableId){
				$.extend( true, $.fn.DataTable.defaults, {
				    "searching": true,
				    "ordering": true,
				    "responsive": true,
				    // change per page values here
				    "lengthMenu": [
				        [10, 15, 20, -1],
				        [10, 15, 20, "All"] 
				    ],
		            "language": {"lengthMenu": " _MENU_ records"},
		            // set default column settings
		            "columnDefs": [{'orderable': true,'targets': [0]},{"searchable": true,"targets": [0]}],
				    // set first column as a default sort by asc
				    "order": [[0, "dec"]],
			    	// set the initial value
				    // "pageLength": ( pageLength ) ? pageLength : 10,   
				    "pageLength":  10,   
				});
			},
			checkboxes : function (table){
				if( table.find('.group-checkable').length ){
			 		table.find('.group-checkable').change(function () {
			            
			            var set = jQuery(this).attr("data-set");
			            var checked = jQuery(this).is(":checked");
			            jQuery(set).each(function () {
			            
			                if (checked) {
			                    $(this).attr("checked", true);
			                } else {
			                    $(this).attr("checked", false);
			                }
			            });

			            jQuery.uniform.update(set);
			        });
				}
			},
			/**
			 * get the fields names that will be display  
			 * map them to data that comes from the window.farmersmarket object.
			 * return array objects. 
			 */
			getCols : function ( table ){
				var self = this;
				var dataCols		= table.find('th');
				var dataColsArray 	= [];
				
				dataCols.each( function (){
					var $this = $(this);
					var className = ( $this.data('class') ) ? $this.attr('data-class') : null;
					
					var isVisible = $this.attr('data-column')!== 'marketsNames';
					
					if( $this.data('column') ){
						dataColsArray.push({
							data 	: $this.attr('data-column'),  
							class 	: className,
							visible : isVisible,
						});
					}

					// build static culumns
					if( $this.data('static')){
						dataColsArray.push({ 
							data : $(this).attr('data-static'),
							defaultContent : self.renderStatics( $(this).attr('data-static') ),
							class : className, 
						});
					}

					//for details column 
					if( $this.data('details') ){
						dataColsArray.push({
			                "class":          "details-control",
			                "orderable":      false,
			                "data":           null,
			                "defaultContent": '<i class="fa fa-plus-circle"></i>'
						});
					}
					
					dataColsArray;
				});

				return dataColsArray;
			},

			renderStatics : function ( staticInput ){
				switch ( staticInput ){
					case "action-checkbox" :
						return '<input type="checkbox" class="checkboxes value=""/>';
						break; 

					case "action-block" :
						return '<a href="javascript:;"><i class="fa fa-ban"></i></a>';
						break;

					case "action-approve" :
						return '<a href="javascript:;"><i class="fa  fa-check"></i></a>';
						break;

					case "action-update-roles" :	
						return '<a href="javascript:;"><i class="fa  fa-gears"></i></a>';
						break;
						
					case "action-delete" :
						return '<a href="javascript:;"><i class="fa  fa-trash"></i></a>';
						break;
					
					case "action-impersonate":
						return '<a href="javascript:;"><i class="fa  fa-user-secret"></i></a>';
						break;
						
					case "satatus" :
						return '<span class="label label-sm"></span>';
						break;	
				}
			},

			showDetails : function (table, dt, tableId){
				var self = this;
				var detailRows = [];
				
				table.find('tbody').on( 'click', 'tr td.details-control', function () {
			        
			        var tr = $(this).closest('tr');
			        var row = dt.row( tr );
			        var idx = $.inArray( tr.attr('id'), detailRows );
			 		
			        if ( row.child.isShown() ) {
			            tr.find('.details-control').children('i').removeClass('fa-minus-circle');
			            tr.find('.details-control').children('i').addClass('fa-plus-circle');
			        
			            tr.removeClass( 'details' );
			            row.child.hide();
			 
			            // Remove from the 'open' array
			            detailRows.splice( idx, 1 );
			        }else {
			            tr.find('.details-control').children('i').removeClass('fa-plus-circle');
			            tr.find('.details-control').children('i').addClass('fa-minus-circle');
			            tr.addClass( 'details' );

					    switch( tableId ) {
						    case "admin-users":
				            row.child( self.detailRowFormat(row.data())).show();
						    break;
						    
						    case "tbl-admin-grantee":
				            row.child( self.detailRowFormatGrantee(row.data())).show();
						    break;

						    case  "tbl-admin-manage-fields":
						    row.child( self.detailRowFormatManageFields(row.data())).show();
						    Metronic.initIcheck();
						    break;
						}
			 
			            // Add to the 'open' array
			            if ( idx === -1 ) {
			                detailRows.push( tr.attr('id') );
			            }
			        }
			    });
			},

			detailRowFormatManageFields : function( detailData ){
				// console.log(detailData);
				var self = this;
				var outPut = "";
				var metaData = detailData.meta;
				outPut += '<h4 class="page-title">' + detailData.label + "  <small><strong>(" + detailData.slug + ")</strong></small></h4>";

				// make sure there is allways extra data to show.
				if( metaData ){
					for( var key in metaData ){
						var options = metaData[key].sectionOptions;
						
						// if (!metaData.hasOwnProperty(key)) continue;
						outPut += '<div class="col-md-4 col-xs-12">';
						outPut += '<div class="panel panel-default">';
						outPut += '<div class="panel-heading clearfix">';
						outPut += '<h3 class="panel-title">'+ options.name +'<button type="button" class="btn btn-primary pull-right update-field-details"><i class="fa fa-save"></i> <span>Save All Changes</span></button></h3>';
						outPut += '<input type="hidden" name="sectionKey" value="'+ key +'" readonly>'
						outPut += '</div>';
						// PANEL BODY
						if ( options.info ) {
							outPut += '<div class="panel-body">';
							outPut += options.info;					
							outPut += '</div>';
						}

						// PANEL TABLE
						outPut += '<table class="table">';
						outPut += '<tbody>';
						
						var section = metaData[key];
						for( var sectionKey in section ){
							
							if( sectionKey !== 'sectionOptions' ){
								outPut += '<tr>'
								outPut += '<td>'+ sectionKey +'</td>';
								outPut += '<td class="text-right"><input type="text" name="' + sectionKey + '"  class="form-control" value="'+ section[sectionKey] +'" '+ self.boolReadOnly( ! options.editable ) +'></td>';
								outPut += '</tr>'							
							}
						}
					
						outPut += '</tbody>';
						outPut += '</table>';
						outPut += '</div></div>';

					}

				}

                return outPut;                        
			},

			detailRowFormatGrantee : function( detailData ){
				output ="";
				reportsIndex = [1,2,3,4];
				detailData.reports.forEach(function(element, index, array){

					currentReportBase 	= 'report_q';
					elmentParent 		= element;					

					output += '<div class="portlet light">';
					output += '<div class="portlet-title">';
					output += '<div class="caption">';
					output += '<i class="fa fa-calendar font-blue-sharp"></i><span class="caption-subject font-blue-sharp"> Progress Report: ' +  parseInt(element.year)  + '</span>';
					output += '</div>'
					output += '<div class="actions">';
					output += '<div class="btn-group btn-group-devided">';
					output += '<a data-year="' + parseInt(element.year) +  '" data-id="'+ element.id +'" class="table-details-actions-tools btn btn-circle btn-warning" href="' + '/api/v1/admin/users/report/' + element.id  + '"><i class="icon-trash"></i> - Delete Report ' + parseInt(element.year) + '</a>';
					output += '</div>';
					output += '</div>';


					output += '<div class="portlet-body">';
					output += '<table class="table table-hover table-light">';
					output += '<thead><tr>';
						output += '<th class="text-center">Quarter</th>';
						output += '<th class="text-center">SNAP Transactions</th>';
						output += '<th class="text-center">SNAP Sales</th>';
						output += '<th class="text-center">Number of Farmers able to redeen SNAP</th>'
						output += '<th class="text-center">Events Hosted</th>';
						output += '<th class="text-center">SNAP materials distributed</th>';
						output += '<th class="text-center">Status</th>';
						output += '<th class="text-center">PDF</th>';
						output += '<th class="text-center">Actions</th>';
					output += '</tr></thead>';
					output += '<tbody>';

					reportsIndex.forEach( function( element, index ) {
						reportIndex = index + 1;
						currentQuarter = elmentParent[ currentReportBase + reportIndex ]; 
						

						if( currentQuarter !== null ){
							// need the user slug o make this work
							var route = window.location.protocol + "//" + window.location.hostname + "/grantee/report-request/" + elmentParent.slug + "/" + elmentParent.year  + "/reportQ" + reportIndex ; 
							var granteeReportRoute = "javascirp:;"; 

							output += '<tr>';
								// output += '<td class="text-center"><a href="' + granteeReportRoute + '">Quarter ' + currentQuarter.slug + '</a></td>';
								output += '<td class="text-center">Quarter ' + currentQuarter.slug + '</td>';
								output += '<td class="text-center">' + currentQuarter.snap_transaction + '</td>';
								output += '<td class="text-center">' + currentQuarter.snap_sales + '</td>';
								output += '<td class="text-center">' + currentQuarter.total_farmers_redeeming_snap + '</td>';
								output += '<td class="text-center">' + currentQuarter.total_events_hosted + '</td>';
								output += '<td class="text-center">' + currentQuarter.snap_material + '</td>';
								output += '<td class="text-center"><span class="label label-sm ' + farmersmarket.labelClass[currentQuarter.status.slug] + '">' + currentQuarter.status.name + '</span></td>';
								
								if( currentQuarter.pdf_name !== "" ){
									output += '<td class="text-center"><a target="_blank" href="/' + currentQuarter.pdf_path + '/' + currentQuarter.pdf_name  + '"><i class="fa fa-file-pdf-o"></td>';
								}else{
									output += '<td class="text-center"> - </td>';
								}

								output += '<td class="text-center"><a data-action="delete-quarter" data-quarter-key="reportQ1" data-report-id="' +  currentQuarter.grantee_report_id + '" class="table-action-single" href="/api/v1/admin/users/grantee/' + currentQuarter.id + '/' + currentQuarter.slug + '"><i class="fa fa-trash"></i></a></td>';
							output += '</tr>';
						
						}
					});

					output += '</tbody>';
					output += '</table>';
					output += '</div></div>';

				});

				return output;			
			}, 

			detailRowFormat : function ( detailData ){
				output = 	'<div class="col-md-6">';
				output += 	'<h3 class="block">Markets:</h3>'; 

				if( $(detailData.markets).length ){
					
					output += '<table class="table table-striped table-bordered">';
					output += '<thead>';
					output += '<tr><th>ID</th><th>Slug</th><th>Market</th></tr>';
					output += '</thead>';
					output += '<tbody>';
					$(detailData.markets).each( function(key){
						output += '<tr>';
						output += '<td>' + this.id + '</td>';
						output += '<td>' + this.slug + '</td>';
						output += '<td><a href="/markets/profile/' + this.slug + '">' + this.name + '</a></td>';
						output += '</tr>';
					});
					output += '</tbody>';
					output += '</table>';
					/*
					output +=	'<ul class="list-unstyled">';
					$(detailData.markets).each( function(key){
						output += '<li><a href="/markets/profile/'+ this.slug +'">' + this.name +"</a></li>";
					});
					output +=	"</ul>";
					*/
				}else{
					output +=	"<p>No markets registred for this user</p>";
				}	

				output +=	"</div>";
				return output;
			},

			// actions for table user adimi
			userTableActions : function ( table, dt ){
				var $that = this;

				table.find('tbody').on('click', 'tr td .table-action-single', function (e){
					
					e.preventDefault();

					var self 	= $(this),
						route 	= self.attr('href'),
						action 	= self.attr('data-action'),
						userId 	= self.attr('data-id');

					item = [userId];

					if( self.attr('data-action') === "update-roles" ){
						currentUserRoles = self.attr('data-roles');

						if( currentUserRoles.indexOf('grantee') !== -1 ) {
							swalText 	= "Remove Grantee role to selected user";
							action 		= 'remove';
						}else{
							swalText = "Add Grantee role to selected user";
							action = 'add';
						} 

						data = {
							'id' 			: self.attr('data-id'),
							'action' 		: action,
							'name' 			: self.attr('data-name'),
							'email' 		: self.attr('data-email'),			            
						}

			            swal({ 
			            	title : "Update Grantee",   
			            	text  : swalText,   
			            	type  : "info",   
			            	showCancelButton: true,   
			            	closeOnConfirm: false,   
			            	showLoaderOnConfirm: true, 

			            }, function(){
			                $.ajax({
			                    headers: { 'X-CSRF-Token': $('#_token').val() },
			                    method: 'PUT',
			                    url:    'users/' + userId + '/grantee',
			                    data:   data,
			                })
			                .done(function( msg, textStatus ) {
				                swal({
				                    title   : "Grantee Role updated", 
				                    text    : msg.success,  
				                    type    : 'success',
				                });  

								setTimeout( function () {
								    dt.ajax.reload( null, false ); // user paging is not reset on reload
								}, 1500 );
								
			                    return true;
			                })
			                .fail(function( msg, textStatus  ) {
			                	if( msg.status == 500 ){

			                		errorMsg = msg.statusText;

			                	}else{

			                		errorMsg = msg.success;
			                	}

				                swal({
				                    title   : "Grantee Role updated", 
				                    text    : errorMsg,  
				                    type    : 'error',
				                });  

			                    return false;
			                });			            		
			            }); 						

					}else if(self.attr('data-action') === 'delete-user'){
						swal({
							title: self.attr('data-action'),
							text: "You are about to Delete the user : " + self.attr('data-name') + ". Note this action can not be un-done." ,
							type: "warning",
							showCancelButton: true,
							closeOnConfirm: false,
							showLoaderOnConfirm: true,
						}, function(){
							
							$.ajax({
								headers: { 'X-CSRF-Token': $('#_token').val() },
								method: 'DELETE',
								url:    window.location.protocol + '//' + window.location.host + '/api/v1/admin/users/' + userId,
								data:   {id:userId},
							})
								.done(function( msg, textStatus ) {
									swal({
										title   : "User was removed successfully",
										text    : msg.success,
										type    : 'success',
									});
									// remove row from data table
									dt.row(self.parents('tr')).remove().draw();
									return true;
								})
								.fail(function( msg,textStatus) {
									swal({
										title   : 'Error',
										text    : msg.responseJSON.error,
										type    : 'error',
									});
									
									return false;
								});
							

						});
						
					}else{
						//prepare data for request
						data = {
			                'action' 	: action,
			                'items'  	: item,    
							//'name' 		: self.attr('data-name'),
							//'email' 	: self.attr('data-email'),			            
			            }

			            swal({ 
			            	title: self.attr('data-action'),   
			            	text: "You are about to " + action + " the user with id: " + userId  ,   
			            	type: "info",   
			            	showCancelButton: true,   
			            	closeOnConfirm: false,   
			            	showLoaderOnConfirm: true, 
			            }, function(){
				
							$.ajax({
								headers: { 'X-CSRF-Token': $('#_token').val() },
								method: 'PUT',
								url:    route,
								data:   data,
							})
								.done(function( msg, textStatus ) {
									swal({
										title   : "Success",
										text    : msg.success,
										type    : 'success',
									});
									
									dt.ajax.reload( null, false );
									// dt.draw();
								})
								.fail(function( msg,textStatus) {
									swal({
										title   : 'Error',
										text    : msg.responseJSON.error,
										type    : 'error',
									});
									return false;
								});
				
				
							/*
							fmAjaxTool.performAjaxRequest({
			            		route 		: route,
			            		method 		: 'PUT',
			            		opt 		: { 'serialize' : false, 'alertWith' : 'swal', },
			            		data 		: data,	
			            	});
							setTimeout( function () {
								// user paging is not reset on reload
								dt.ajax.reload( null, false );
								dt.draw();
								
							}, 1500 );
							*/
			            });
						
					}

				});
			},
			
			changeStatusActions:function(table, dt){
				var self = this;
				// update via select tag
				table.find('tbody').on('change', 'tr td .change_user_status', function (e){
					var $this = $(this);

					$wrapper = $this.closest('.input-group');
					$btn = $wrapper.find('.btn-change-status');
					$btn.attr('disabled', false);

					self.changeStatus($wrapper.find('.route').val(), $this.val(), $this, $btn);
					
				});
				
				// table.find('tbody').on('click', 'tr td .', function (e){
				// });
			},

			tablActionGrantee : function ( table, dt ) {
				var $that = this;

				table.find('tbody').on('click', 'tr td a.table-action-single', function (e) {
					e.preventDefault();
					var self 	= $(this),
						route 	= self.attr('href'),
						action 	= self.attr('data-action'),
						userId 	= self.attr('data-id');
					var item = [userId];

					if( self.attr('data-action') === "delete-quarter" ){
						data = {
							reportId 	: self.attr('data-report-id'),
							quarterKey 	: self.attr('data-quarter-key'),
							action : action,
						}

			            swal({ 
			            	title : "Delete Quarterly Report",
			            	text  : "Your are about to delete this report. Are you sure you want to do this?",   
			            	type  : "info",   
			            	showCancelButton: true,   
			            	closeOnConfirm: false,   
			            	showLoaderOnConfirm: true, 

			            }, function( ){
							
			                $.ajax({
			                    headers: { 'X-CSRF-Token': $('#_token').val() },
			                    method: 'DELETE',
			                    url:    route,
			                    data:   data,
			                })
			                .done(function( msg, textStatus ) {
				                swal({
				                    title   : "Quarterly Report", 
				                    text    : msg.success,  
				                    type    : 'success',
				                });  

								setTimeout( function () {
								    dt.ajax.reload( null, false ); // user paging is not reset on reload
								}, 1500 );
								
			                    return true;
			                })
			                .fail(function( msg, textStatus  ) {
				                swal({
				                    title   : "Quarterly Report", 
				                    text    : msg.success,  
				                    type    : 'error',
				                });  
			                    return false;
			                });			            		


			            }); 						

					}
				});

			    table.find('tbody').on('click',  '.table-details-actions-tools', function(e){
			        e.preventDefault();
			        var self = $(this);
			            // usersTable      = $('#admin-users'),
			            // tableCheckBoxes = table.find('input[type="checkbox"]:checked');

			        data = {
			            'year'  : self.attr('data-year'),
			            'id'    : self.attr('data-id'),
			        }   

			        swal({ 
			            title: 'Delete Report',   
			            text: "You are about to delete the " + self.attr('data-year') + " progress report and all of its data. Are you sure you want to continue?",   
			            type: "warning",   
			            showCancelButton: true,   
			            closeOnConfirm: false,   
			            showLoaderOnConfirm: true, 

			        }, function(){ 
		                $.ajax({
		                    headers: { 'X-CSRF-Token': $('#_token').val() },
		                    method: 'DELETE',
		                    url:    self.attr('href'),
		                    data:   data,
		                })
		                .done(function( msg, textStatus ) {
			                swal({
			                    title   : "Report Status", 
			                    text    : msg.success,  
			                    type    : 'success',
			                });  

							setTimeout( function () {
							    dt.ajax.reload( null, false ); // user paging is not reset on reload
							}, 1500 );
							
		                    return true;
		                
		                })

		                .fail(function( msg, textStatus  ) {


			                swal({
			                    title   : "Report Status", 
			                    text    : msg.success,  
			                    type    : 'error',
			                });  

		                    return false;
		                });	
			        });

			    });
			},
			
			changeStatus:function($route, $status, $select, $btn){
				$select.attr('disabled', true);
				$btn.attr('disabled', true);
				$btn.children('i').attr('class', 'fa fa-spinner fa-pulse fa-fw');
				
				$.ajax({
					headers: { 'X-CSRF-Token': $('#_token').val() },
					method: 'PUT',
					url:    $route,
					data:   {
						status:$status
					},
				})
					.done(function( msg, textStatus ) {
						$select.attr('disabled', false);
						// $btn.attr('disabled', false);
						$btn.children('i').attr('class', 'fa fa-check');
					})
					.fail(function( msg, textStatus  ) {
						$select.attr('disabled', false);
						// $btn.attr('disabled', false);
						$btn.children('i').attr('class', 'fa fa-check');
					});
			},
			/**
			 * Build html options tag for select input. with provided array
			 * @param  data 			array, options value
			 * @param  selectedOption 	string, optional, value of options that sould be seted as selected by deault.
			 * @return                	string.
			 */
			buildOptionsForSelectElement : function( data, selectedOption ){
				selectedOption = ( selectedOption !== 'undefined' ) ? selectedOption : false; 				 
				var $options = '<option value="">Select an option</option>';
				data.forEach( function(element, index) {
					var selected = ( element.slug === selectedOption ) ? "selected" : null;
					$options += '<option value="' + element.slug +'" '+ selected +' >'+ element.name +'</option>';
				});

				return $options;
			},

			/**
			 * return 3er  provided argument if validaiton is true 
			 * @param dbVal     string, value comming from db
			 * @param domVal    stirng, value to compare to.
			 * @param returnVal string
			 */
			setValueIfTrue : function( dbVal, domVal, returnVal ){
				return ( dbVal === domVal ) ? returnVal : false;
			},

			boolCheck : function(value){
				// var boolVal = ! isNaN(value) ? true : value;
				var boolVal = parseInt(value);

				if( boolVal == true){
					return "checked";
				}
			},
			
			boolReadOnly : function(value){
				// var boolVal = ! isNaN(value) ? true : value;
				var boolVal = ! isNaN(value) ? value : parseInt(value);
				if( boolVal == true){
					return "readonly";
				}
			},
			
			

		}
	
	}()); 

	window.fmDataTable  = fmDataTable;
}(window));