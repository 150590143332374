(function(window){

	//http://www.daterangepicker.com/
	var fmDateRangeInput = (function(){

		return {

			// @param inputObj, 		jquery obj
			// @param inputStartDate, 	jquery obj, optional
			// @param inputEndDate, 	jquery obj, optional
			// @param inputValidated, 	jquery obj, optional, input that will be activated after date has been picked
			init : function ( inputObj, inputStartDate, inputEndDate, inputValidated ) {

				inputStartDate 	= ( typeof( inputStartDate ) !== 'undefined' ) ? inputStartDate : false;
				inputEndDate 	= ( typeof( inputEndDate ) !== 'undefined' ) ? inputEndDate : false;
				inputValidated	= ( typeof(inputValidated) !== 'undefined') ? inputValidated :false;

				inputObj.daterangepicker(
					{
					    opens: (Metronic.isRTL() ? 'left' : 'right'),
					    startDate: moment().subtract('days', 29),
					    endDate: moment(),
					    minDate: '01/01/2015',
					    maxDate: '12/31/' + new Date().getFullYear(),
					    // dateLimit: {days: 60},
					    showDropdowns: true,
					    showWeekNumbers: true,
					    timePicker: false,
					    timePickerIncrement: 1,
					    timePicker12Hour: true,
					    ranges: {
					        'Today'			: [moment(), moment()],
					        // 'Yesterday'	: [moment().subtract('days', 1), moment().subtract('days', 1)],
					        'Last 7 Days'	: [moment().subtract('days', 6), moment()],
					        'Last 30 Days'	: [moment().subtract('days', 29), moment()],
					        'This Month'	: [moment().startOf('month'), moment().endOf('month')],
					        'Last Month'	: [moment().subtract('month', 1).startOf('month'), moment().subtract('month', 1).endOf('month')]
					    },
					    buttonClasses: ['btn'],
					    applyClass: 'green',
					    cancelClass: 'default',
					    format: 'MM/DD/YYYY', //'YYYY-MM-DD'
					    separator: ' to ',
					    locale: {
					        applyLabel: 'Apply',
					        fromLabel: 'From',
					        toLabel: 'To',
					        customRangeLabel: 'Custom Range',
					        daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
					        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
					        firstDay: 1
					    }
					}
				);

				// when a date has been applied
				inputObj.on('apply.daterangepicker', function(ev, picker) {
				    // display applied date
				    // $('#reportrange span').html(picker.startDate.format('MMMM D, YYYY') + ' - ' + picker.endDate.format('MMMM D, YYYY'));
				    inputObj.children('span').html(picker.startDate.format('MMMM D, YYYY') + ' - ' + picker.endDate.format('MMMM D, YYYY'));

					// if there is a validation proccess
				 	if( inputValidated ){
				 		inputValidated.removeAttr('disabled');
				 	}
				 	
				 	// inputs use to post dates
				 	if( inputStartDate ){
				     	inputStartDate.val(picker.startDate.format('MM/DD/YYYY'));
				     	inputEndDate.val(picker.endDate.format('MM/DD/YYYY'));
				 	}
				});				


				// cancel button clicked
				inputObj.on('cancel.daterangepicker', function(ev, picker) {

				    inputObj.children('span').html("");
					
					// if there is a validation proccess
				 	if( inputValidated ){
				 		inputValidated.attr('disabled', 'disabled');
				 	}
					
				 	// inputs use to post dates
				 	if( inputStartDate ){
				     	inputStartDate.val("");
				     	inputEndDate.val("");
				 	}					
				});				

			}

		}

	}());

	window.fmDateRangeInput = fmDateRangeInput;

}(window))