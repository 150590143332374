(function(window) {

    var fmValidator = (function(){

        return {
            /**
             * validate provided inputs
             * @param   inputs  array of jqueryObjects
             * @return  void
             */
            getTabRules : function ( inputs ) {

                var self = this;
                formInputRules = {};
                formInputRules = {};
                inputs.each( function(){
                    var item = $(this);

                    if( item.data('rules') ) {
                        formInputRules[item.attr('name')] =  self.setRules(item.data('rules'));
                    }
                });

                return formInputRules;          
            },

            setRules : function ( rulesString ){
                attrs = rulesString.split("|");
                rulesObj = {};

                // get rules attrs 
                for (var i = attrs.length - 1; i >= 0; i--) {
                    if( attrs[i].indexOf(':') == -1 ){
                        rulesObj[attrs[i]] = true;        
                    }else{
                        rule = attrs[i].split(":");        
                        rulesObj[ rule[0] ] = rule[1];
                    }
                };

                return rulesObj;
            },
            /**
             * [validate description]
             * @param  form         jqueryObject of form been validated, 
             * @param  singleInput  object of the input to be validated, use on changeEvent.
             * @return              boolean
             */
            validate : function (form, singleInput){
                
                singleInput = singleInput ? singleInput : false; 

                var inputsRules = form.find('input.form-control');
                var error           = $('.alert-danger', form);
                var success         = $('.alert-success', form);
                
                form.validate({
                    
                    doNotHideMessage    : true, //this option enables to show the error/success messages on tab switch.
                    errorElement        : 'span', //default input error message container
                    errorClass          : 'help-block help-block-error', // default input error message class
                    focusInvalid        : false, // do not focus the last invalid input
                    rules               : this.getTabRules( inputsRules ),// custom messages 
                    messages            : {},
                    // render error placement for each input type
                    errorPlacement: function (error, element) {
                        switch(element.attr("type")){
                            case 'radio':
                                 element.closest('.radio-list').append(error);
                                 break;
                            case 'checkbox':
                                 element.closest('.checkbox-list').append(error);
                                 break;
                            default:
                                error.insertAfter(element);
                                break;
                        }
                    },

                    //display error alert on form submit   
                    invalidHandler: function (event, validator) { 
                        success.hide();
                        error.show();
                        Metronic.scrollTo(error, -200);
                    },
                    
                    highlight: function (element) { // hightlight error inputs
                        $(element)
                            .closest('.form-group').removeClass('has-success').addClass('has-error'); // set error class to the control group
                    },
                    
                    unhighlight: function (element) { // revert the change done by hightlight
                        $(element)
                            .closest('.form-group').removeClass('has-error'); // set error class to the control group
                    },

                    success: function (label) {},
                });                

                // validate an specific input
                if(singleInput){
                    return form.validate().element($(singleInput));
                }

                // validate all form inputs 
                if( form.valid()  === false ){
                    return false;
                } else{
                    return true;
                }
            },
        }

    }());

    window.fmValidator = fmValidator;

}(window));