(function(window){
    var fmParseAndSerializeInputs = (function(){
        return {
            /**
             * Get Inputs and Serialize them, this will help to get data from  ichekc checkboxes, switches, when ever they are disabled, 
             * values are soupuse to booleans || integers 
             * @param  inputs,  array, jquery objects.
             * @return string,  serialize string of input key values pair.
             */
            init : function(inputs) {
                data = "";                
                inputs.each(function(index, item){
                    data += (index !== 0) ? "&" : ""; 
                    data += $(this)[0].name + "=" + escape($(this)[0].value); 
                });
                return data;

            }
        }
    }());
    window.fmParseAndSerializeInputs = fmParseAndSerializeInputs;    
}(window));