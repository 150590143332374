(function(windown){
	// https://github.com/enyo/dropzone/wiki/Combine-normal-form-with-Dropzone
	// https://laracasts.com/discuss/channels/general-discussion/dropzonejs-in-a-normal-form?page=1
	// http://www.dropzonejs.com/#usage
	// http://maxoffsky.com/code-blog/howto-ajax-multiple-file-upload-in-laravel/
	// http://www.dropzonejs.com/bootstrap.html

	var fmDropzone = (function(){

		return {
			/**
			 * init api
			 * @param  data 				object
			 * REQUIRED FIELDS	
			 * @param  data.configElement   jqueryObject, dom element where file upload template vars are set 
			 * OPTIONAL FIELDS
			 * @param  data.previewsContainer, 		string, css slector of element that will be use as a preview area 
			 * @param  data.maxFilesize     int, maximum file size in mb
			 * @param  data.acceptedFiles   int, string of file extensions that will be allow ( .jpg, .png )
			 * @param  data.uploadMultiple  boolean, allow for multiple or single file upload
			 * @param  data.previewThumbSize int, preview thumb size for width and heigth
			 * @return                 
			 */
			init : function( data ){
				//setup defaults
				route 					= data.configElement.attr('data-route'); 	
				showBtnUpload  			= typeof(data.configElement.attr('data-showUpload')) 		!== "undefined" ? parseInt(data.configElement.attr('data-showUpload')) : true;
				showBtnCancel   		= typeof(data.configElement.attr('data-showCancel')) 		!== "undefined" ? parseInt(data.configElement.attr('data-showCancel')) : true ;
				showProgressBar 		= typeof(data.configElement.attr('data-showProgress')) 		!== "undefined" ? parseInt(data.configElement.attr('data-showProgress')) : true ;
				showPreViewArea 		= typeof(data.configElement.attr('data-showPreview')) 		!== "undefined" ? parseInt(data.configElement.attr('data-showPreview')) : true ;
				gridView 				= typeof(data.configElement.attr('data-gridView')) 			!== "undefined" ? parseInt(data.configElement.attr('data-gridView')) : false; 
				previewThumbSize 		= typeof(data.configElement.attr('data-previewThumbSize'))	!== "undefined"	? parseInt(data.configElement.attr('data-previewThumbSize')) : 80;
				
				previewsContainer		= typeof(data.previewsContainer) 	!== "undefined" 	? data.previewsContainer : '#dropzone-previews';
				maxFilesize  			= typeof(data.maxFilesize) 			!== "undefined"  	? data.maxFilesize : 2;
				acceptedFiles			= typeof(data.acceptedFiles) 		!== "undefined" 	? data.acceptedFiles : this.getAcceptedFiles('images'); 
				uploadMultiple 			= typeof(data.uploadMultiple) 		!== "undefined" 	? data.uploadMultiple : true; 

				this.uploaderConstructor({
					route           	: route,  	
					showBtnUpload   	: showBtnUpload, 
					showBtnCancel   	: showBtnCancel, 
					showProgressBar 	: showProgressBar, 
					showPreViewArea 	: showPreViewArea, 
					previewsContainer	: previewsContainer, 
					maxFilesize  		: maxFilesize, 
					acceptedFiles		: acceptedFiles,  
					uploadMultiple 		: uploadMultiple,  
					previewThumbSize 	: previewThumbSize,
					gridView 			: gridView,				
				});
			},


			uploaderConstructor : function(data){
				//counter for image object returned
				var nFile = 0;
				
				// Get the template HTML and remove it from the doument
				var previewNode = document.querySelector('#template');
					previewNode.id = "";

				var previewTemplate = previewNode.parentNode.innerHTML;
				previewNode.parentNode.removeChild(previewNode);

				var actionContainer 	= $('#actions'),
					currentTabRoute		= data.route,
					csrfToken 			= document.querySelector('#_token').value;

				// if upload btn is not been shown start upload automatic	
				var autoQueue =  ( ! data.showBtnUpload );	
				
				var myDropzone = new Dropzone( data.previewsContainer, { 
					url: currentTabRoute, // Set the url
					method: 'POST',
					headers : { 'X-CSRF-Token' : csrfToken },
					maxFilesize : data.maxFilesize,
					paramName : 'fm-dropzone-file', 			//'fm-img-file' input name to be refrence in backend
					acceptedFiles : data.acceptedFiles, 
					uploadMultiple : data.uploadMultiple,
					thumbnailWidth: data.previewThumbSize,
					thumbnailHeight: data.previewThumbSize,
					parallelUploads: 20,
					previewTemplate: previewTemplate,
					autoQueue: autoQueue, 						// false: manual, true: automatic,  Make sure the files aren't queued until manually added
					previewsContainer: data.previewsContainer, 	// Define the container to display the previews
					clickable: ".fileinput-button", 			// Define the element that should be used as click trigger to select files.
				});
				
				myDropzone.on("addedfile", function(file) {
					// Hookup the start button
					document.querySelector('#dropzone-previews').removeAttribute('style');
					document.querySelector('#total-progress').removeAttribute('style');

					if( data.showBtnUpload ){	
						file.previewElement.querySelector(".dropzone-btn-current-start").onclick = function(e) {
							e.preventDefault(); 
							myDropzone.enqueueFile(file); 
						};
					}
				});

				// Update the total progress bar
				myDropzone.on("totaluploadprogress", function(progress) {
					document.querySelector("#total-progress .progress-bar").style.width = progress + "%";
				});

				myDropzone.on("sending", function(file) {
					// Show the total progress bar when upload starts
					document.querySelector("#total-progress").style.opacity = "1";
					// And disable the start button
					if( data.showBtnUpload ){	
						file.previewElement.querySelector(".dropzone-btn-current-start").setAttribute("disabled", "disabled");
					}
				});

				// Hide the total progress bar when nothing's uploading anymore
				myDropzone.on("queuecomplete", function(progress) {
					document.querySelector("#total-progress").style.opacity = "0";
				});

				//when  successfull uploaded
				myDropzone.on("success", function(file, response) {
					
					// file.previewElement.querySelector('.progress').style.opacity = "0";	
					file.previewElement.querySelector('.progress').style.display = "none";	
					if( data.showBtnUpload ){	
						file.previewElement.querySelector('.dropzone-btn-current-cancel').setAttribute("disabled", "disabled");		
						file.previewElement.querySelector('.dropzone-btn-current-delete').setAttribute("disabled", "disabled");		
					}

					file.previewElement.querySelector('.success').innerHTML = response.success;

					if( data.gridView && typeof(response.files[nFile]) !== "undefined" ){
						
						var file_path = window.location.protocol + '//' + window.location.hostname + '/' + response.files[nFile].path +  response.files[nFile].name;
						
						file.previewElement.querySelector('.gallery-thumb-preview').setAttribute('data-lity', true);
						file.previewElement.querySelector('.gallery-thumb-preview').setAttribute('href', file_path );
						nFile ++;
					}
				});	


				/**
				 * Setup the buttons for all transfers
				 * The "add files" button doesn't need to be setup because the config
				 * `clickable` has already been specified.
				 */
				
				// marke sure btn its in use
				if( data.showBtnUpload ){
					document.querySelector(".dropzone-btn-start").onclick = function(e) {
						e.preventDefault();
						myDropzone.enqueueFiles(myDropzone.getFilesWithStatus(Dropzone.ADDED));
					};
					
				}

				// marke sure btn its in use
				if( data.showBtnCancel ){
					document.querySelector(".dropzone-btn-cancel").onclick = function() {
						myDropzone.removeAllFiles(true);
					};
				}
			},

			getAcceptedFiles : function( fileType ){
				switch (fileType) {
					case 'images':
					return '.jpg, .jpeg, .png, .bmp, .gif';
					break;
				
					default:
					return '.jpg, .jpeg, .png, .bmp, .gif';
					break;
				}
			},
		}

	}());


	window.fmDropzone = fmDropzone;					
}(window));