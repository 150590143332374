(function(window){

	return fmLoadingIcon  = ( function (){
	
		return {
			/**
			 * Change the icon while submiting
			 * @param  IconState            	string, start, end (submition)
			 * @param  elem                 	string || obj, css selector of the element || element this.
			 * @param  currentIconClass     	string, optional, current fa icon class, that will be removed
			 * @param  replacementIconClass 	string, optional, class animation which will be use.
			 * @param  disabledToggle			boolean, disable the element while submiting
			 * @return string.                      
			 */
			init : function ( IconState, elem, currentIconClass, replacementIconClass, disabledToggle ) {
				// defaults values.
				currentIconClass 		= (currentIconClass) 		? currentIconClass 		: false;
				replacementIconClass 	= (replacementIconClass)  	? replacementIconClass 	: 'fa-spinner fa-pulse fa-fw';
				disabledToggle			= (disabledToggle) 			? disabledToggle 		: false;
				
				this[IconState]( elem, currentIconClass, replacementIconClass, disabledToggle );
			},
	
			start : function (elem, currentIconClass, replacementIconClass, disabledToggle) {
				$elem =  $(elem);
				
				// disable element, if requested, while is been submited
				if(disabledToggle){
					$(elem).attr('disabled', 'disabled');
					$(elem).addClass('disabled');
				} 

				if( currentIconClass ){

					$elem.find('i').removeClass(currentIconClass);
					$elem.find('i').addClass(replacementIconClass );
					
				}else{

					$elem.find('i').attr('class', " ");
					$elem.find('i').addClass( "fa " + replacementIconClass );

				}
			},
			
			end : function ( elem , currentIconClass, replacementIconClass,disabledToggle ) {
				// replacementIconClass = ( typeof(replacementIconClass) !== 'undefined' ) ? replacementIconClass : 'fa-spinner fa-pulse fa-3x fa-fw';
				// disable element, if requested, while is been submited
				if(disabledToggle){
					$(elem).removeAttr('disabled');
					$(elem).removeClass('disabled');
				} 

				$(elem).find('i').attr('class', "");
				$(elem).find('i').addClass(replacementIconClass);
			},
		}
		
	}());

	window.fmLoadingIcon = fmLoadingIcon;

}(window))