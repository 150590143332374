;(function($){
  
  /**
   * jQuery function to prevent default anchor event and take the href * and the title to make a share popup
   *
   * @param  {[integer]} intWidth   [Popup width defalut 500]
   * @param  {[integer]} intHeight  [Popup height defalut 400]
   * @param  {[boolean]} blnResize  [Is popup resizeabel default true]
   */
  $.fn.customerPopup = function (customLink, intWidth, intHeight, blnResize) {
    // Prevent default anchor event
    // e.preventDefault();
    // Set values for window
    intWidth = intWidth || '500';
    intHeight = intHeight || '400';
    strResize = (blnResize ? 'yes' : 'no');
    
    var shareLink = customLink || this.attr('href');

    // Set title and open popup with focus on it
    var strTitle = ((typeof this.attr('title') !== 'undefined') ? this.attr('title') : 'Social Share'),
        strParam = 'width=' + intWidth + ',height=' + intHeight + ',resizable=' + strResize;
        objWindow = window.open(shareLink, strTitle, strParam).focus();
      
  }
  
  /* ================================================== */
  
  $(document).ready(function ($) {
    // $('.btn-custom-share').on("click", function(e) {
    //     $(this).customerPopup(e);
    // });
  });
    
}(jQuery));