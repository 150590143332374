var conditionalFormWizardFields = function () {
	
	return {

		init: function (){
            //set conditional fields Display
            this.handelConditionalFields($('.conditional-display').find('.form-control'));

            //conditianl field
            this.onClickDisplay($('.inputToggleDisplay'));
            
            this.elementToggleDisplay( $('.elementToggleDisplay') );

            if( $('*[name="incorporation_method"]') ){

                $('*[name="incorporation_method"]').on('change', function (){

                    if( $(this).val() === "501-c"){

                        $('*[name="incorporation_method_notes"]').closest('.conditional-form-group').hide('slow');
                        $('*[name="type_501c"]').closest('.conditional-form-group').show('slow');
                        
                    } else if( $(this).val() === "other"){

                        $('*[name="type_501c"]').closest('.conditional-form-group').hide('slow');
                        $('*[name="incorporation_method_notes"]').closest('.conditional-form-group').show('slow');
                        
                    } else{

                        $('*[name="type_501c"]').closest('.conditional-form-group').hide('slow');
                        $('*[name="incorporation_method_notes"]').closest('.conditional-form-group').hide('slow');

                    }

                });
            }
		},

        /**
         * Conditinal fields base in a parent field value
         * display only if paretn value === data-displayvalue
         * TODO: when ever the display data is false ( children is set to no display, set children input back to default )
         */
        handelConditionalFields : function ( inputs ){
            if( inputs ){
                // apply only for markets profile edit not in the markets form wizzard
                if( window.location.pathname.indexOf("/markets/profile/") !== -1 ){
    
                    inputs.each(function (){
                        
                        var self        = $(this);
                        var displayVal  = self.closest('.conditional-display').data('displayvalue');
                        var childName   = self.closest('.conditional-display').data('children');

                        if( self.val() == displayVal ){

                            $('*[name="' + childName  + '"]').closest('.conditional-form-group').show('slow');

                        }
                    });
                }
                
                inputs.on('click', function(){
                    var self        = $(this);
                    var displayVal  = self.closest('.conditional-display').data('displayvalue');
                    var childName   = self.closest('.conditional-display').data('children');

                    if( self.val() == displayVal ){
                        $('*[name="' + childName  + '"]').closest('.conditional-form-group').show('slow');
                    }else{
                        $('*[name="' + childName  + '"]').closest('.conditional-form-group').hide('slow');
                    }
                });
            
            }
        },
        
        elementToggleDisplay : function ( input ){
            
            input.on('click', function(){

                var self = $(this),
                    elementToDisplay = self.attr('data-display-element'),
                    display = $(elementToDisplay); 
                
                // display when ever an element of the group is checked
                if( self.attr('data-this-element') !== undefined && self.attr('data-this-element') === "group" ){
                    if ( self.find("input:checkbox:checked").length > 0){
                        display.show('slow');
                    }else{
                        display.hide('slow');
                    }
                }

                // display when clicked element is cheked
                if( self.attr('data-this-element') !== undefined && self.attr('data-this-element') === "single" ){
                    // if ( self.find("input:checkbox:checked").length > 0){
                    if ( self.is(':checked') ){
                        display.show('slow');
                    }else{
                        display.hide('slow');
                    }
                }
            });
        },
        
        onClickDisplay : function ( input ){
            if( input ){
                // apply only for markets profile edit not in the markets form wizzard
                if( window.location.pathname.indexOf("/markets/profile/") !== -1 ){
                    input.each( function () {
                        inputToToggle = $(this).attr('data-toggleDisplay');
                        if( $(this).is(':checked') ){
                            $("*[name='" +  inputToToggle + "']").closest('.conditional-form-group').show('slow');
                        }
                    });
                }

                input.on('click', function (){
                    inputToToggle = $(this).attr('data-toggleDisplay');
                    $("*[name='" +  inputToToggle + "']").closest('.conditional-form-group').toggle('slow');
                });
            }
        },
    };

}();