(function(window){
	var fmSlugify = (function(){
		return {
			/**
 			 * @param $string
			 * @param $sparator
			 * @param $prefix
			 * @param $sufix
			 * @returns {string}
			 */
			init: function($string, $sparator, $prefix, $sufix){
				
				var sparator = $sparator || '-';
				
				var prefix = $prefix
					? $prefix + '-'
					: '';
				
				var sufix = $sufix
					? '-' + $sufix
					:'';
				
				var slugedString =  $string.toString().toLowerCase()
					.replace(/\s+/g, sparator)           // Replace spaces with -
					.replace(/[^\w\-]+/g, '')       // Remove all non-word chars
					.replace(/\-\-+/g, sparator)         // Replace multiple - with single -
					.replace(/^-+/, '')             // Trim - from start of text
					.replace(/-+$/, '');            // Trim - from end of text
				

				return prefix + slugedString + sufix;
			}
		}
	}());
	
	window.fmSlugify = fmSlugify;
	
}(window))