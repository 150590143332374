(function(window) {

	var fmFileUploader = (function(){

		return {
			/**
			 * wrapper for file input plugin
			 * https://github.com/kartik-v/bootstrap-fileinput
			 * @param inputData object 				object, required 
			 * @param inputdata.input 				jqueryObject, required
			 * @param inputData.maxFileCount 		int.
			 * @param inputData.browseLabel 		string
			 * @param inputData.browseIcon 			string
			 * @param inputData.reloadOnSuccess 	bool
			 * @param inputData.uploadFor 			string, method name of relationship bwt, FmFile and model from where file it's been uploaded 
			 * @param inputData.parentBtn 			jqueryObj, element use to display input form. (optional)
			 * @param inputData.doOnSuccess			callaback fn, function call after file uploaded success
			 * @param inputData.doOnError			callaback fn, function call after file uploaded error
			 * @param inputData.showUpload			bool, file-input propertie
			 * @param inputData.showCaption			bool, file-input propertie
			 * @param inputData.showPreview			bool, file-input propertie
			 * @param inputData.showRemove			bool, file-input propertie
			 * @param inputData.showCancel			bool, file-input propertie
			 * @param inputData.dataFields			array [ { 'element_id' : { required: bool } }, ... ]
			 */
			init : function( inputData )
			{
				// jquery object, dom element with basic data for plugin
				var inputObj = inputData.input;
				
				this.setFileUploader({
					input 				: inputObj,
					maxFileSize  		: (typeof(inputObj.attr('data-file-size')) 	!== 'undefined'	? inputObj.data('file-size') 	: 2500),	//2.5mb	
					uploadAsync 		: (typeof(inputData.uploadAsync)			!== 'undefined' ? inputData.uploadAsync 		: true), 
					maxFileCount 		: (typeof(inputData.maxFileCount) 			!== 'undefined'	? inputData.maxFileCount 		: 1), // single file
					browseLabel	 		: (typeof(inputData.browseLabel) 			!== 'undefined'	? inputData.browseLabel 		: " Browse" ),
					browseIcon 	 		: (typeof(inputData.browseIcon) 			!== 'undefined'	? inputData.browseIcon 			: "folder-open" ),
					reloadOnSuccess		: (typeof(inputData.reloadOnSuccess) 		!== 'undefined'	? inputData.reloadOnSuccess 	: true), // reload page after file has been successfully uploaded default false.
					parentBtn 			: (typeof(inputData.parentBtn)				!== 'undefined' ? inputData.parentBtn 			: false), // jqueryObj of the btn use for displaying the actual form input
					doOnSuccess 		: (typeof(inputData.doOnSuccess) 			!== 'undefined' ? inputData.doOnSuccess 		: false),
					doOnError 			: (typeof(inputData.doOnError)		 		!== 'undefined' ? inputData.doOnError 			: false),
					doOnSelect 			: (typeof(inputData.doOnSelect)	 			!== 'undefined' ? inputData.doOnSelect 			: false),
					showUpload			: (typeof(inputData.showUpload) 			!== 'undefined' ? inputData.showUpload 			: false),
					showCaption			: (typeof(inputData.showCaption) 			!== 'undefined' ? inputData.showCaption 		: true),
					showPreview			: (typeof(inputData.showPreview) 			!== 'undefined' ? inputData.showPreview 		: false),
					showRemove			: (typeof(inputData.showRemove) 			!== 'undefined' ? inputData.showRemove 			: false),
					showCancel			: (typeof(inputData.showCancel) 			!== 'undefined' ? inputData.showCancel 			: false),
					autoUpload 			: (typeof(inputData.autoUpload)    			!== 'undefined' ? inputData.autoUpload 			: true),
					dataFields			: inputData.dataFields || false,
					inputId		 		: inputObj.attr('id'),
				});
			},

			setFileUploader : function ( setData )
			{
				var that  = this;
				var bntSuccessLabel = "Browse";
				var btnSuccessIcon 	= "fa-folder-open";
				// file uploader insntace
				fileUplodInput = setData.input;
				successEl =  $('#' + setData.inputId + '-success');
				errorElId =  "#" + setData.inputId + "-error";
				// route for post
				routeUrl =  fileUplodInput.attr('data-route');
				//extra data to post
				//uploadExtraData = {'_token': $('#_token').val() };
				// uploadExtraData = that.getExtraDataForRequestHeaders(setData.dataFields);

				var test = fileUplodInput.fileinput({
					showUpload		: setData.showUpload,
					showCaption		: setData.showCaption,
					showPreview		: setData.showPreview,
					showRemove 		: setData.showRemove,
					showCancel 		: setData.showCancel,
					// file options
					maxFileSize		: setData.maxFileSize,
					maxFileCount 	: setData.maxFileCount,
					autoReplace 	: true,
					// allowedFileExtensions 	: ['csv', 'xlsx', 'xls'],
					// allowedFileTypes 		: ['html', 'text', 'object'],
					//select file btn
					browseLabel		: setData.browseLabel,
					browseClass		: "btn btn-primary",
					browseIcon 		: "<i class=\"fa fa-" +  setData.browseIcon + "\"></i> ",
					//delete btn
					removeClass		: "btn btn-danger",
					removeLabel		: "Delete",
					removeIcon 		: "<i class=\"fa fa-trash\"></i> ",
					//upload btn
					uploadClass		: "btn btn-info",
					uploadLabel		: "Upload",
					uploadIcon 		: "<i class=\"fa fa-upload\"></i> ",
					// ajax
					uploadAsync		: setData.uploadAsync,
					uploadUrl		: routeUrl,
					//uploadExtraData : uploadExtraData,
					uploadExtraData : function(previewId, index){
						var $headers = { '_token': $('#_token').val() };
						
						if(!setData.dataFields.length){
							return $headers;
						}
						
						$(setData.dataFields).each(function($i, $item){
							$element = document.getElementById($item.element.id);
							$headers[$element.name] = $element.value;
						});
						
						return $headers;
					},
					// error continer
					elErrorContainer: errorElId,
					// errorMsgs
					msgSizeTooLarge 			: 'File "{name}" ({size} KB) exceeds maximum allowed upload size of {maxSize} KB. Please retry your upload!',
					msgFilesTooLess 			: 'You must select at least {n} {files} to upload. Please retry your upload!',
					msgInvalidFileType 			: 'Invalid type for file "{name}". Only "{types}" files are supported.',
					msgInvalidFileExtension		: 'Invalid extension for file "{name}". Only "{extensions}" files are supported.',
					// progress bar styling
					progressClass 				: 'progress-bar progress-bar-success progress-bar-striped active',
					// layout
					layoutTemplates : {
				    	progress 	: 	'<div class="progress">\n' +
				        				'<div class="progress-bar progress-bar-success progress-bar-striped text-center" role="progressbar" aria-valuenow="{percent}" aria-valuemin="0" aria-valuemax="100" style="width:{percent}%;">\n' +
				        				'{percent}%\n' + '</div>\n' + '</div>',
				    	footer 		: 	'<div class="file-thumbnail-footer">\n' + '<div class="file-caption-name" style="width:{width}">{caption}</div>\n' + '{progress} {actions}\n' + '</div>',
					
				    	main1 		: '{preview}\n' + '<div class="input-group input-group-lg {class}">\n' + '{caption}\n' +'<div class="input-group-btn">\n' + '{remove}\n' +'{cancel}\n' +'{upload}\n' +'{browse}\n' + '</div>\n' + '</div>' + '<div class="kv-upload-progress hide"></div>',

					},

					// slug file name before send it
					slugCallback: function(filename) {
    					return (! filename ) ? '' : String(filename).replace(/[\-\[\]\/\{}:;#%=\(\)\*\+\?\\\^\$\|<>&"']/g, '_');
					},

				}).on("filebatchselected", function(event, files) {
					
					// validate if upload should be automaticly || submiting form || plugin submit button.
					if(setData.autoUpload){
				    	fileUplodInput.fileinput("upload");
					}
					
					if( setData.doOnSelect ){
						setData.doOnSelect()
					}

				}).on('filepreajax', function(event, previewId, index){
					// data.extra = that.getExtraDataForRequestHeaders(setData.dataFields);
				}).on('filebatchpreupload', function(event, data, id, index) {
					that.uploadBtnUpdate( this, 'Processing', 'fa-spinner fa-pulse', 'fa-folder-open');
				}).on('filebatchuploadsuccess', function(event, data) {
					
					// out = data.jqXHR.responseJSON.response;
					outPut = data.jqXHR.responseJSON;
					swal({
							title : "Good Job!",
							text: outPut.response,
							html: true,
							type: "success",
						}, function(){
							if( setData.reloadOnSuccess ){
								location.reload();
							}
						}
					);

					if( setData.parentBtn ){
						var filePath = window.location.protocol + '//' + window.location.hostname + '/' + outPut.filePath;
						setData.parentBtn.children('i').removeClass('fa-upload');
						setData.parentBtn.children('i').addClass('fa-eye');
						setData.parentBtn.children('span').text('View Award Document');
						setData.parentBtn.removeAttr('data-toggle');
						setData.parentBtn.attr('target', '_blank');
						setData.parentBtn.attr('href', filePath );
					}
		    	
			    	// display feed back on dom
			    	successEl.append(outPut.response);
			    	successEl.fadeIn('slow');
			    	
			    	// update Btn
					that.uploadBtnUpdate( this, bntSuccessLabel, btnSuccessIcon, 'fa-spinner fa-pulse' );

				}).on('fileuploaderror', function(event, data, msg) {
			    	var form 		= data.form,
			    		files 		= data.files,
			    		extra 		= data.extra,
			        	response 	= data.response,
			        	reader 		= data.reader;
					
					that.uploadBtnUpdate( this, 'Browse', 'fa-folder-open', 'fa-spinner fa-pulse');

				}).on('filebatchuploaderror', function(event, data, msg) {
    				var form = data.form,
    				files = data.files,
    				extra = data.extra,
        			response = data.response,
        			reader = data.reader;

					that.uploadBtnUpdate( this, 'Browse', 'fa-folder-open', 'fa-spinner fa-pulse');

				});
			},

			uploadBtnUpdate : function ( btn, btnLabel, btnIconAdd, btnIconRemove )
			{
				uploadBtnTagI = $(btn).parent(".btn-file").children("i");
				uploadBtnTagSpan = $(btn).parent(".btn-file").children("span");
				
				uploadBtnTagI.removeClass(btnIconRemove);
				uploadBtnTagI.addClass(btnIconAdd);
				uploadBtnTagSpan.html(btnLabel);
			},

			getExtraDataForRequestHeaders: function(dataFields){
				var $headers = { '_token': $('#_token').val() };
				if(!dataFields.length){
					return $headers;
				}
				$(dataFields).each(function($i, $item){
					$element = document.getElementById($item.element.id);
					$headers[$element.name] = $element.value;
				});
				return $headers;
			}
		}
	}());

    window.fmFileUploader = fmFileUploader;

}(window));