var InputsTools = function () {

    var handleInputMasks = function () {

        $.extend($.inputmask.defaults, {
            'autounmask': true
        });


        $(".input-mask-date").inputmask("y/m/d", {
            "placeholder": "yyyy/mm/dd"
        }); //multi-char placeholder

        $(".input-mask-year").inputmask("y", {
            "placeholder": "yyyy"
        });

        $(".input-mask-phone").inputmask("mask", {
            "mask": "(999) 999-9999"
        }); //specifying fn & options


        $(".input-mask-number").inputmask({
            "mask": "9",
            "repeat": 10,
            "greedy": false
        }); // ~ mask "9" or mask "99" or ... mask "9999999999"
        
        var inputNumberFormat = $('input.number_format');

        inputNumberFormat.keydown(function (e) {
            // Allow: backspace, delete, tab, escape, enter and .
            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                 // Allow: Ctrl+A, Command+A
                (e.keyCode == 65 && ( e.ctrlKey === true || e.metaKey === true ) ) || 
                 // Allow: home, end, left, right, down, up
                (e.keyCode >= 35 && e.keyCode <= 40)) {
                     // let it happen, don't do anything
                     return;
            }
            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        }).keyup(function(e){
            var down_val = $(this).data('prev-val');
            if(isNaN($(this).val())){
                $(this).val(down_val);
            }
            
            $(this).data('prev-val', $(this).val());
        }).blur(function(e){
            var decimals = $(this).data('currency-decimals')=='' ? 0 : Number($(this).data('currency-decimals'));
            
            $(this).val(thousandFormat($(this).val(), decimals));
            $(this).removeAttr('data-prev-val');
        }).focus(function(e){
            var val = $(this).val();
            if(val!=''){
                val = Number(val.split(',').join(''));
                $(this).val(val);
            }
        });

        // apply formating form get go.
        if( inputNumberFormat.length ){
            inputNumberFormat.each(function(index){
                var decimals = $(this).data('currency-decimals')=='' ? 0 : Number($(this).data('currency-decimals'));
                $(this).val(thousandFormat($(this).val(), decimals));
                $(this).removeAttr('data-prev-val');
            });
        }

        // use for formating any none input element
        if($('span.format-float').length){
            $('span.format-float').each(function(){
                var val = parseFloat( $(this).text() )
                $(this).text(thousandFormat(val, 2));
            });   
        }


        $(".input-mask-decimal").inputmask('decimal', {
            rightAlignNumerics: false
        }); //disables the right alignment of the decimal input


        $(".input-mask-tin").inputmask({
            "mask": "99-9999999",
            placeholder: "" // remove underscores from the input mask
        }); //specifying options only
        
        $(".input-mask-zipcode").inputmask({
            "mask": "99999",
            placeholder: "" // remove underscores from the input mask
        });

        $(".ein").inputmask("99-9999999", {
            placeholder: " ",
            clearMaskOnLostFocus: true
        }); //default        

        /*
        $("#mask_date").inputmask("d/m/y", {
            autoUnmask: true
        }); //direct mask        
        $("#mask_date1").inputmask("d/m/y", {
            "placeholder": "*"
        }); //change the placeholder
        
        

        
        $("#mask_currency").inputmask('€ 999.999.999,99', {
            numericInput: true
        }); //123456  =>  € ___.__1.234,56

        $("#mask_currency2").inputmask('€ 999,999,999.99', {
            numericInput: true,
            rightAlignNumerics: false,
            greedy: false
        }); //123456  =>  € ___.__1.234,56
        
        $("#mask_ssn").inputmask("999-99-9999", {
            placeholder: " ",
            clearMaskOnLostFocus: true
        }); //default

        */
    }

    var handleDatePickers = function () {

        if (jQuery().datepicker) {
            $('.date-picker').datepicker({
                rtl: Metronic.isRTL(),
                orientation: "left",
                autoclose: true
            });
            //$('body').removeClass("modal-open"); // fix bug when inline picker is used in modal
        }

        /* Workaround to restrict daterange past date select: http://stackoverflow.com/questions/11933173/how-to-restrict-the-selectable-date-ranges-in-bootstrap-datepicker */
    }

    var handlerSelectMult = function () {
        if( $(".chosen-select") ){
            $(".chosen-select").chosen({width: "95%"})
        }
    }

    return {
        init: function () {
            handleInputMasks();
            handleDatePickers();
            handlerSelectMult();
        }
    };
}();

function thousandFormat(x, decimals){
    x = Number(x).toFixed(decimals);
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
}