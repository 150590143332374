$('document').ready(function(){
    //create a market bnt handler
    $('.add-market-btn').on('click', function(e){
        
        e.preventDefault();
        var self = $(this);

        swal({   
                title: "Create A new Market!",   
                text: "Give your new market a name",   
                type: "input",   
                showCancelButton: true,   
                closeOnConfirm: false,   
                showLoaderOnConfirm: true, 
                animation: "slide-from-top",   
                inputPlaceholder: "Market name", 
                inputName: "market_name",
            }, 

            function(inputValue)
            {   
                if (inputValue === false) return false;      
                
                if (inputValue === "") {     
                    swal.showInputError("You need to give it a name");     
                    return false;
                };


                //perform ajax request
                $.ajax({
                    headers: { 'X-CSRF-Token': $('#_token').val() },
                    method: 'POST',
                    url:    self.attr('href'),
                    data:   { 'name' : inputValue }
                })
                                
                .done(function( msg, textStatus ) {

                    // self.displayAlertWith( ajaxCallData.opt.alertWith, "success", msg );
                    swal({   
                            title: "Market created!",   
                            text: "Now you need to add your market's profile information.",   
                            showConfirmButton: true, 
                            type: 'success',
                        
                        },function(){  
                            window.location.replace( msg.redirect );
                        }
                    );
                    return true;
                })

                .fail(function( msg, textStatus  ) {
                    // self.displayAlertWith( ajaxCallData.opt.alertWith, "warning", msg );
                    return false;
                });

            }
        );    
    });

});   