(function (window, fmDataTable, fmAjaxTool ){


    /**
     *  Implement aditional fn to data tables 
     *  Inline editable tables
     */
    var fmEditableTable = ( function (){

        return {

            init : function ( table, dataToDisplay, addMoreBtn ) {

                self = this;

                //table to ouput
                // var oTable =  fmDataTable.init( table, dataToDisplay ); 
                var oTable = table.dataTable();

                var nEditing = null;
                var nNew = false;
                
                addMoreBtn.click(function (e) {
                    e.preventDefault();

                    //if add row btn is click while editing  or creating a row
                    if (nNew && nEditing) {
                        self._alert('simple', { title : 'You have an unsave Row.', text : 'Please save current Row before adding a new one', type: 'info'} );
                        return;
                    }


                    var aiNew = oTable.fnAddData( self._getInputsValue(table) );

                    var nRow = oTable.fnGetNodes(aiNew[0]);

                    self.editRow(oTable, nRow, table);
                    

                    nEditing = nRow;
                    
                    nNew = true;
                });

                table.on('click', '.save', function (e){
                    e.preventDefault();
                     // Get the row as a parent of the link that was clicked on
                    var nRow = $(this).parents('tr')[0];                    

                    data = self._validateAndSerialize( nRow );
                    if( data ) {
                        $(this).addClass('disabled');
                        $(this).text('submiting...');
                        $.ajax({
                            headers : { 'X-CSRF-Token': $('#_token').val() },
                            method  : 'POST',
                            url     : $(table).data('route-save'),
                            data    : data,
                        })
                        
                        
                        .done( function( msg, textStatus ) {
            
                            self.saveRow(oTable, nEditing, table);
                            nEditing = null;       

                            fmAjaxTool.displayAlertWith( 'fmNotification', "success", msg );
                            self._headerAlert('hide');
                        
                        })

                        .fail(function( msg, textStatus  ) {
                            
                            $(this).removeClass('disabled');
                            $(this).text('save');

                            fmAjaxTool.displayAlertWith( 'fmNotification', "warning", msg );
                            self._headerAlert('show');
                        });
                    }
                });

                

                table.on('click', '.delete', function (e) {
                    e.preventDefault();

                    if (confirm("Are you sure to delete this row ?") == false) {
                        return;
                    }

                    var nRow = $(this).parents('tr')[0];
                    oTable.fnDeleteRow(nRow);
                    alert("Deleted! Do not forget to do some ajax to sync with backend :)");
                });



                table.on('click', '.cancel', function (e) {
                    e.preventDefault();
                    if (nNew) {
                        oTable.fnDeleteRow(nEditing);
                        nEditing = null;
                        nNew = false;
                    } else {
                        restoreRow(oTable, nEditing);
                        nEditing = null;
                    }


                    self._headerAlert('hide');
                });


                table.on('click', '.edit', function (e) {

                    e.preventDefault();
                    
                     // Get the row as a parent of the link that was clicked on 
                    var nRow = $(this).parents('tr')[0];


                     // Currently editing - but not this row - restore the old before continuing to edit mode 
                    if ( nEditing !== null && nEditing != nRow ) {
                        
                        self.restoreRow(oTable, nEditing);
                    
                        self.editRow(oTable, nRow);
                    
                        nEditing = nRow;
                    
                    
                    } else if ( this.innerHTML == "save") {
                        // Editing this row and want to save it 

                        self.saveRow(oTable, nEditing, table);
                        
                        nEditing = null;
                        
                        alert("Updated! Do not forget to do some ajax to sync with backend :)");
                    
                    } else {
                         // No edit in progress - let's start one 
                        self.editRow(oTable, nRow, table);
                        nEditing = nRow;
                    }
                });
            },

            _alert : function ( type, data ) {


                switch (type) {

                    case "simple" :
                        swal(data.title, data.text, data.type);                    
                    break;


                    case "callBack" :
                        
                        swal({
                            title:  data.title,   
                            text:   data.text,   
                            type:   data.type,
                            showCancelButton: true,
                            confirmButtonText: "Yes, Save it!",   
                            cancelButtonText: "No, cancel plx!",
                            closeOnConfirm: false,   
                            closeOnCancel: false,                                                        
                            
                            }, function(isConfirm){   

                                if (isConfirm) {     
                                    
                                    swal("Deleted!", "Your metric has been deleted.", "success");   
                                
                                } else {    
                                
                                    swal("Cancelled", "Your ", "error");

                                }   
                        })

                    break;
                }
            },


            editRow : function (oTable, nRow, tableElement) {

                var self = this;

                var aData = oTable.fnGetData(nRow);
                var jqTds = $('>td', nRow);

                return self._generateInputs( tableElement, jqTds, aData );
            },

            restoreRow : function  (oTable, nRow) {
                var aData = oTable.fnGetData(nRow);
                var jqTds = $('>td', nRow);

                for (var i = 0, iLen = jqTds.length; i < iLen; i++) {
                    oTable.fnUpdate(aData[i], nRow, i, false);
                }

                oTable.fnDraw();
            },        

            saveRow : function (oTable, nRow, tableElment ) {
                var jqInputs = $('input, select', nRow);


                var actionsCols = tableElment.find('th');
                var actionsColsArray = [];

                for (var i = 0; i < actionsCols.length; i++) {

                    if( $(actionsCols[i]).data('action') ){

                        actionsColsArray.push(actionsCols[i])
                    }

                };


                //add to the jqInputs length the length for action inputs wich are not included in the jqInputs to be output it
                totalInputsToOutput = ( actionsColsArray.length > 0 ) ? actionsColsArray.length + jqInputs.length : jqInputs.length;

                addedInputsTotal =  jqInputs.length;
                x = 0;
                for (var i = 0; i < totalInputsToOutput ; i++) {

                    if( jqInputs[i] ){

                        oTable.fnUpdate( jqInputs[i].value, nRow, i, false);
                    
                    }else{

                        if( $(actionsColsArray[x]).data('action') ){

                            var swapAction = {
                                "view"      : { ico : 'fa-search', color : 'btn-default' },
                                "delete"    : { ico : 'fa-times', color : 'btn-default' }
                            }   

                            dataAction      = $(actionsColsArray[x]).attr('data-action');
                            actionLabel     = dataAction;
                            actionIcon      = swapAction[ dataAction ].ico;
                            actionColor     = swapAction[ dataAction ].color;

                            // oTable.fnUpdate('<a class="' + $(actionsColsArray[x]).attr('data-action') + '" href="" > ' + $(actionsColsArray[x]).attr('data-action') + ' </a>', nRow, i , false);
                            oTable.fnUpdate( '<a class=" btn btn-sm btn-circle btn-editable ' + actionColor + ' ' + actionLabel  + '" href="" ><i class="fa ' +  actionIcon + '"></i> ' + actionLabel + ' </a>' , nRow, i , false);
                            x++;
                        }
                    }
                };

                oTable.fnDraw();
            },

            cancelEditRow : function (oTable, nRow) {
                var jqInputs = $('input', nRow);
                oTable.fnUpdate(jqInputs[0].value, nRow, 0, false);
                oTable.fnUpdate(jqInputs[1].value, nRow, 1, false);
                oTable.fnUpdate(jqInputs[2].value, nRow, 2, false);
                oTable.fnUpdate(jqInputs[3].value, nRow, 3, false);
                oTable.fnUpdate('<a class="edit" href="">Edit</a>', nRow, 4, false);
                oTable.fnDraw();
            },                        

            _generateInputs : function ( tableElment, jqTds, aData ){

                var self = this;
                
                var dataCols = tableElment.find('th');


                for ( var i = 0; i < dataCols.length; i++) {

                    if( $(dataCols[i]).data('ignore') ){

                    
                        jqTds[i].innerHTML =  '<input type="hidden" class="form-control input-small" name="" disabled="disabled" value="' +  $(dataCols[i]).attr('data-column') +'">';

                    
                    }else if ( $(dataCols[i]).data('action') ) {
                        //if action is edit, change it for save in adding mode.
                        // actionLabel = ( $(dataCols[i]).attr('data-action') === "view" )     ? "save" : $(dataCols[i]).attr('data-action');    
                        // actionLabel = ( $(dataCols[i]).attr('data-action') === "delete" )   ? "cancel" : $(dataCols[i]).attr('data-action');    
                        // actionLabel = ( $(dataCols[i]).attr('data-action') === "delete" )   ? "cancel" : $(dataCols[i]).attr('data-action');    

                        var swapAction = {
                            "view"      : { label : "save", ico : 'fa-check', color : 'btn-info' },
                            "delete"    : { label : "cancel", ico : 'fa-ban', color : 'btn-warning' }
                        }   


                        dataAction      = $(dataCols[i]).attr('data-action');
                        actionLabel     = swapAction[ dataAction ].label;
                        actionIcon      = swapAction[ dataAction ].ico;
                        actionColor     = swapAction[ dataAction ].color;
                    
                        jqTds[i].innerHTML = '<a class=" btn btn-sm btn-circle btn-editable ' + actionColor + ' ' + actionLabel  + '" href="" ><i class="fa ' +  actionIcon + '"></i> ' + actionLabel + ' </a>';
                    
                    }else if ( $(dataCols[i]).data('static') ){


                        jqTds[i].innerHTML = self._getStaticInput( $(dataCols[i]) );



                    }else if( $(dataCols[i]).data('column') ){
                            
                        // get rules
                        rules       = ( $( dataCols[i]).data('rules') ) ?  'data-rules="' + $(dataCols[i]).attr('data-rules') + '"' : "";
                        typeAttr    =  ( $(dataCols[i]).data('type') ) ? $(dataCols[i]).data('type') : "text";

                        jqTds[i].innerHTML =  '<input type="' +  typeAttr  + '" class="form-control input-small" ' + rules + '  name="' + $(dataCols[i]).attr('data-column') + '"  value="' + aData[i] + '">';
                        
                    }


                };

                return jqTds;
            },

            _getInputsValue : function (table) {

                var self = this;
                
                var dataCols        = table.find('th');
                var dataColsArray   = [];

                dataCols.each( function (){
                    // add an emtoy string to the array that will be use as a value for out put it inputs    
                    dataColsArray.push('');
                
                });

                return dataColsArray;
            },

            _getStaticInput : function ( inputElement ){

                if( inputElement.attr('data-static') === 'season' ){
                    return '<select class="form-control" name="season" data-rules="required"><option value="">Season</option><option value="fall">Fall</option><option value="winter">Winter</option><option value="spring">Spring</option><option value="summer">Summer</option></select>';
                }


                if ( inputElement.attr('data-static') === 'season_year' ){
                    var select = '<select name="season_year" class="form-control" data-rules="required" style="min-width: 80px;">';
                    for(var year = 2014; year < 2018; year++){
                        select += '<option val="'+year+'">'+year+'</option>';
                    }
                    select += '</select>';
                    return select;
                    //return '<input type="text" class="form-control input-small input-mask-year" name="season_year" placeholder="yyyy" data-rules="required">'
                }
            },

            _validateAndSerialize : function ( rowInputs ){

                var self = this;

                inputs = $(rowInputs).find('.form-control');

                errors = [];

                inputs.each(function(){

                    if( $(this).data('rules') ){

                        if( $(this).val() === ""){

                            $(rowInputs).find('*[name="' +  $(this).attr('name') + '"]').css('border-color', 'red');                                    
                           
                            errors.push( this );

                        }                        
                    }
                });

                
                if( errors.length > 0 ){
                    
                    self._headerAlert('show');
                    return false; 
                }


                return $(inputs).serialize();
            },

            _headerAlert : function (action){
                
                if ( action === "show" ){

                    $('#display-alerts').children('.alert-danger').show('slow');

                    return;
                    
                }


                $('#display-alerts').children('.alert-danger').hide('slow');
            },
        }

    }());

    window.fmEditableTable = fmEditableTable;

}(window, fmDataTable, fmAjaxTool ));